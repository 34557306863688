import React, { useState } from "react";
import { Form, Input, Drawer, Button } from "antd";
import { ContractAutocomplete } from "src/shared";

const DocSalesDrawer = (props) => {
  const api = `https://${process.env.REACT_APP_APP_URL}/api/v1/`;
  const [visible, setVisible] = useState(false);
  return (
    <div>
      <Button
        onClick={() => setVisible(true)}
        style={{ width: "100%", marginBottom: 12 }}
      >
        Доп. параметры
      </Button>
      <Drawer
        title="Дополнительные параметры"
        placement="right"
        onClose={() => setVisible(false)}
        visible={visible}
        width={500}
      >
        <Form.Item label="Номер" name="number">
          <Input />
        </Form.Item>
        <Form.Item label="Комментарий" name="comment">
          <Input />
        </Form.Item>
        <Form.Item label="Договор" name="contract">
          <ContractAutocomplete
            onSelect={props.onSelectContract}
            api={api}
            token={props.token}
          />
        </Form.Item>
      </Drawer>
    </div>
  );
};

export default DocSalesDrawer;
