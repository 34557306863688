import React, { useEffect, useContext, useState } from "react";
import {
  Form,
  Input,
  Select,
  Upload,
  Button,
  message,
  Radio,
  AutoComplete,
  InputNumber,
} from "antd";
import { NomenclatureContext } from "src/shared/lib/hooks/context/getNomenclatureContext";
import { API } from "src/shared";

import debounce from "lodash.debounce";
import { apiRequests } from "src/shared/api/api";

const formItemLayout = { labelCol: { span: 5 }, wrapperCol: { span: 17 } };

export default function Nomenclature({
  formContext,
  record,
  handleSaveImage,
  handleDeleteImage,
  setChangeData,
  withoutImage = false,
}) {
  const {
    manufacturersData = [],
    categoriesData = [],
    unitsData = [],
    token,
  } = useContext(NomenclatureContext);
  const [manufacturers, setManufacturers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [units, setUnits] = useState([]);
  const [nomenclature, setNomenclature] = useState([]);
  const [optionsName, setOptionsName] = useState([]);

  const { TextArea } = Input;

  const getNomenclature = API.crud.get(token, "/nomenclature");

  const validateMessages = {
    /* eslint-disable no-template-curly-in-string */
    required: "Поле обязательно для ввода!",
  };

  const onChangeName = (e) => {
    setOptionsName(
      nomenclature.filter((n) =>
        n.value.toLowerCase().includes(e.toLowerCase())
      )
    );
  };

  const debounceOnChangeName = debounce(onChangeName, 500);

  useEffect(() => {
    if (unitsData.length !== 0) {
      const unitsSelect = [];
      for (let item of unitsData) {
        unitsSelect.push({ value: item.id, label: item.name });
      }
      setUnits(unitsSelect);
    }
    if (manufacturersData.length !== 0) {
      const manufacturersSelect = [];
      for (let item of manufacturersData) {
        manufacturersSelect.push({ value: item.id, label: item.name });
      }
      setManufacturers(manufacturersSelect);
    }
    if (categoriesData.length !== 0) {
      const categoriesSelect = [];
      for (let item of categoriesData) {
        categoriesSelect.push({ value: item.id, label: item.name });
      }
      setCategories(categoriesSelect);
    }
    if (record?.pictures !== undefined && record?.pictures.length !== 0) {
      const picturesDefault = [];
      for (let item of record.pictures) {
        picturesDefault.push({
          id: item.id,
          name: item.url,
          status: "done",
          url: `https://${process.env.REACT_APP_APP_URL}/api/v1/${item.url}/`,
        });
      }
      setPictures(picturesDefault);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manufacturersData, categoriesData, unitsData]);

  useEffect(() => {
    const initial = async () => {
      await getNomenclature().then((res) =>
        setNomenclature(res.result.map((el) => ({ value: el.name })))
      );
    };

    if (!nomenclature.length) {
      initial();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (record?.id) {
      apiRequests.pictures
        .getItems({
          entity: "nomenclature",
          entity_id: record.id,
        })
        .then((response) => {
          setPictures(
            response.data.result.map((item) => ({
              id: item.id,
              name: item.url,
              status: "done",
              url: `https://${process.env.REACT_APP_APP_URL}/api/v1/${item.url}/`,
            }))
          );
        });
    }
  }, [record?.id]);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onValuesChange = (changedValues, allValues) => {
    setChangeData(true);
  };

  return (
    <Form
      {...formItemLayout}
      form={formContext}
      initialValues={record}
      onValuesChange={onValuesChange}
      layout={"horizontal"}
      style={{ maxWidth: "100%" }}
      validateMessages={validateMessages}
    >
      <Form.Item rules={[{ required: true }]} label={"Имя"} name={"name"}>
        <AutoComplete options={optionsName} onChange={debounceOnChangeName} />
      </Form.Item>
      <Form.Item label={"Тип"} name={"type"}>
        <Radio.Group>
          <Radio.Button value="product"> Товар </Radio.Button>
          <Radio.Button value="service"> Услуга </Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label={"Краткое описание"} name={"description_short"}>
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item label={"Длинное описание"} name={"description_long"}>
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item label={"Код"} name={"code"}>
        <Input />
      </Form.Item>
      <Form.Item label={"Единица измерения"} name={"unit"}>
        <Select filterOption={filterOption} showSearch options={units} />
      </Form.Item>
      <Form.Item label={"Категория"} name={"category"}>
        <Select filterOption={filterOption} showSearch options={categories} />
      </Form.Item>
      <Form.Item label={"Производитель"} name={"manufacturer"}>
        <Select
          filterOption={filterOption}
          showSearch
          options={manufacturers}
        />
      </Form.Item>
      <Form.Item label={"Кешбек"} name={"cashback_percent"}>
        <InputNumber />
      </Form.Item>
      {!withoutImage ? (
        <Form.Item label={"Изображение"}>
          <Upload
            action={`https://${process.env.REACT_APP_APP_URL}/api/v1/pictures/?token=${token}&entity=nomenclature&entity_id=${record.id}`}
            fileList={pictures}
            listType="picture-card"
            onChange={({ fileList: newFileList, file: newFile }) => {
              if (newFile.status === "done") {
                handleSaveImage(newFile.response);
                setPictures(newFileList);
                return message.success("Изображение было добавлено");
              }
              setPictures(newFileList);
            }}
            onRemove={(picture) => {
              if (picture.response !== undefined) {
                return handleDeleteImage(picture.response.id);
              }
              handleDeleteImage(picture.id);
            }}
          >
            <Button>Загрузить</Button>
          </Upload>
          {/* <div style={{padding: '10px 0', display: 'flex', gap: 10}}>
            {
              pics.map(pic => <PreviewImage items={[pic]} width={60} height={60} key={pic.id} />)
            }
          </div> */}
        </Form.Item>
      ) : null}
    </Form>
  );
}
