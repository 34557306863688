import React, { useCallback, useMemo } from "react";
import { Table, Button, Popconfirm, Tooltip } from "antd";
import { EditOrganizations, EditOrganizationPaybox } from "src/features/Modal";
import { DeleteOutlined } from "@ant-design/icons";
import { EditableCell, EditableRow } from "src/shared";
import { COL_ORGANIZATIONS } from "../model/constants";
import { setColumnCellProps } from "../lib/setCollumnCellProps";
import { parseTimestamp } from "../../Form";

export default function Organizations({
  handleRemove,
  handleSave,
  dataSource,
}) {
  const parseDate = useCallback(
    (key) => parseTimestamp(dataSource, key),
    [dataSource]
  );

  const newData = Array.from(parseDate("registration_date"));

  const columns = useMemo(
    () =>
      setColumnCellProps(COL_ORGANIZATIONS, {
        full_name: [
          {
            key: "render",
            action: (_, record) => (
              <Tooltip placement="topLeft">{record.name}</Tooltip>
            ),
          },
        ],
        registration_date: [
          {
            key: "render",
            action: (_, record) => {
              return (
                <p>
                  {
                    new Date(record.registration_date)
                      .toISOString()
                      .split("T")[0]
                  }
                </p>
              );
            },
          },
        ],
        action: [
          {
            key: "render",
            action: (_, record) => (
              <>
                <EditOrganizationPaybox record={record} />
                <EditOrganizations record={record} handleSave={handleSave} />
                <Popconfirm
                  title={"Подтвердите удаление"}
                  onConfirm={() => handleRemove(record.id)}
                >
                  <Button icon={<DeleteOutlined />} />
                </Popconfirm>
              </>
            ),
          },
        ],
      }),
    [handleRemove, handleSave]
  );

  return (
    <>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={newData}
        components={{
          body: {
            cell: EditableCell,
            row: EditableRow,
          },
        }}
        bordered
        rowClassName={() => "editable-row"}
        style={{ width: "100%" }}
      />
    </>
  );
}
