import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col, Space, Table, Checkbox } from "antd";
import { apiRequests } from "src/shared/api/api";



function ModulButtonModal({ query, websocket }) {
  const [state, setState] = useState({
    idIntegration: 3,
    buttonLoad: true,
    blockButton: false,
    accountsLoad: false,
  });
  const [data, setData] = useState();
  const [accounts, setAccounts] = useState([]);

  const check = async () => {
    await apiRequests.integrationModule
      .getIntegrationCheck({
        id_integration: state.idIntegration,
      })
      .then(async (res) => {
        setState((prev) => ({ ...prev, isAuth: res.data?.isAuth }));
        if (data?.action === "check") {
          console.log("check");
          setData((prev) => ({
            ...prev,
            integration_status: data?.integration_status,
          }));
        }
        if (res.data?.isAuth) {
          await apiRequests.integrationModule
            .getIntegrationAccounts({
              id_integration: state.idIntegration,
            })
            .then((res) => {
              // setAccounts(res.data.result);
              setState((prev) => ({ ...prev, accountsLoad: false }));
            })
            .catch((err) => {
              console.log(err);
            });
        }
        console.log(data);
      });
  };

  const columns = [
    {
      title: "название",
      dataIndex: "name",
      key: "name",
      render: (value, record) => {
        return value.slice(0, value.indexOf('№') + 1).replace('№', `№${record.id}`)
      }
    },
    { title: "валюта", dataIndex: "currency", key: "currency" },
    { title: "Тип", dataIndex: "accountType", key: "accountType" },
    {
      title: "Активно",
      dataIndex: "is_active",
      key: "is_active",
      render: (value, record) => (
          <Checkbox
              defaultChecked={value}
              onChange={async ({ target }) => {
                setState((prev) => ({ ...prev, accountsLoad: true }));
                await apiRequests.integrationModule
                    .patchIntegrationAccount(record.id, {
                      is_active: target.checked,
                    })
                    .then(async () => await check());
              }}
          />
      ),
    },
  ]

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showModal = () => {
    console.log("showModal function called");
    console.log("query:", query);
    console.log("state.idIntegration:", state.idIntegration);

    apiRequests.integrationModule
        .getBills({
          token: query?.token,
          id_integration: state.idIntegration,
        })
        .then((res) => {
          console.log(res)
          setAccounts(res.data.result)
        })

    apiRequests.integrationModule
      .getIntegrationOn({
        token: query?.token,
        id_integration: state.idIntegration,
      })
      .then(() => {
        console.log("getIntegrationOn API request successful");
        setState({ ...state, isModalVisible: true });
      })
      .catch((error) => {
        console.log("Error in getIntegrationOn API request:", error);
      });
    setState({ ...state, isModalVisible: true });
  };

  const handleOk = () => {
    setState({ ...state, isModalVisible: false });
  };

  const handleCancel = () => {
    apiRequests.integrationModule.getIntegrationOff({
      token: query?.token,
      id_integration: state.idIntegration,
    });
    setState({ ...state, isModalVisible: false, isAuth: data.isAuth });
  };

  return (
    <>
      <Button
        onClick={() => {
          check();
          showModal();
        }}
      >
        {state?.isAuth ? "Настройки" : "Установить виджет"}
      </Button>
      <Modal
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        width={800}
        title={"Настройка: интеграция tablecrm.com и Модуль Банк"}
        open={state.isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ danger: true, disabled: state.blockButton }}
        okButtonProps={{ disabled: state.blockButton }}
        cancelText="Отключить виджет"
        okText="Закрыть"
      >
        {state?.isAuth ? (
          <>
            Авторизаия выполнена успешно
            <Table
              loading={state.accountsLoad}
              dataSource={accounts}
              columns={columns}
            />
          </>
        ) : (
          <>
            <Space size={"small"} direction="vertical">
              <Row>
                <Col>
                  Необходимо пройти аутентификацию. Вас перенаправит на
                  странницу авторизации modulbank.ru. Выберите компании и
                  разрешения для получения данных в tablecrm.com
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    loading={!state.buttonLoad}
                    onClick={async () => {
                      setState((prev) => ({
                        ...prev,
                        buttonLoad: true,
                        blockButton: true,
                      }));
                      await apiRequests.integrationModule
                        .getLink({
                          token: query?.token,
                          id_integration: state.idIntegration,
                        })
                        .then((res) => {
                          setState((prev) => ({
                            ...prev,
                            buttonLoad: false,
                            blockButton: false,
                          }));
                          return res;
                        })
                        .then((res) => {
                          window.location.replace(res?.data?.link);
                        })
                        .catch(() => {
                          setState((prev) => ({
                            ...prev,
                            buttonLoad: false,
                            blockButton: false,
                          }));
                        });
                    }}
                  >
                    выполнить
                  </Button>
                </Col>
              </Row>
            </Space>
          </>
        )}
      </Modal>
    </>
  );
}

export default ModulButtonModal;
