import React, { useState } from "react";
import { DatePicker, Form, Input, Select } from "antd";
import { ContragentAutocomplete, DebounceSelect } from "src/shared";
// import dayjs from 'dayjs'
// import { axiosInstance } from "src/shared/api/axiosInstance";
import { apiRequests } from "src/shared/api/api";
import CreateDocsSalesWrapper from "src/features/Modal/ui/CreateDocsSalesWrapper";
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const Rent = (props) => {
  // const [priceCache, setPriceCache] = useState([])
  const [contragent, setContragent] = useState(null);
  const [docSales, setDocSales] = useState([]);
  console.log(docSales);

  const fetchUsers = async (name) => {
    return fetch(
      `https://${process.env.REACT_APP_APP_URL}/api/v1/cashbox_users/?token=${props.token}&name=${name}`
    )
      .then((response) => response.json())
      .then((body) => {
        if (body.result) {
          return body.result.map((user) => ({
            label: `${user.first_name} ${user.last_name ? user.last_name : ""}`,
            value: user.id,
          }));
        }
        return [];
      });
  };

  // const fetchNomenclature = async (name) => {
  //   return fetch(
  //     `https://${process.env.REACT_APP_APP_URL}/api/v1/nomenclature/?token=${props.token}&name=${name}`
  //   )
  //     .then((response) => response.json())
  //     .then((body) => {
  //       if (body.result) {
  //         return body.result.map((item) => ({
  //           label: `${item.name}`,
  //           value: item.id,
  //         }));
  //       }
  //       return [];
  //     });
  // };

  // const calcPrice = async (id) => {
  //   const nomenclatures = props.form.getFieldValue('nomenclature')

  //   const first_date = props.form.getFieldValue('date_range')[0].startOf('day')
  //   const second_date = props.form.getFieldValue('date_range')[1].startOf('day')
  //   const date_different = second_date.diff(first_date, 'day')

  //   if (nomenclatures.length > 0) {
  //     const requests = nomenclatures.map((item) => {
  //       const cache = priceCache.find(i => i.id === item.key)
  //       if (cache) {
  //         return cache.data
  //       } else {
  //         return axiosInstance.get(`/alt_prices/${item.key}/`).catch(e => e)
  //       }
  //     })
  //     Promise.allSettled(requests)
  //       .then((result) => {
  //         console.log(result)
  //         const price = result.map(item => {
  //           if (item.status === 'fulfilled') {
  //             if (typeof item.value === 'number') {
  //               return (Number(item.value) * Number(date_different))
  //             } else if (item.value.status === 200) {
  //               setPriceCache(prev => ([...prev, {
  //                 id: item.value.data.nomenclature_id,
  //                 data: item.value.data.price
  //               }]))

  //               return item.value.data.price * date_different
  //             }
  //           }
  //         })
  //         const value = price?.filter(Number).reduce((acc, val) => acc + val) || 0
  //         console.log(price?.filter(Number))
  //         props.form.setFieldValue('sum_period', value.toFixed(2))
  //       }).catch((e) => console.log('e', e))
  //   }

  // }

  const fetchDocsSales = async (id) => {
    return fetch(
      `https://${process.env.REACT_APP_APP_URL}/api/v1/docs_sales/?token=${props.token}`
    )
      .then((response) => response.json())
      .then((body) => {
        if (body.result) {
          setDocSales(body.result);
          return body.result.map((item) => ({
            label: `${item.id}`,
            value: item.id,
          }));
        }
        return [];
      });
  };

  // const fetchContragents = useCallback(
  //   async (name) => {
  //     return fetch(
  //       `https://${process.env.REACT_APP_APP_URL}/api/v1/contragents/?token=${props.token}&name=${name}`
  //     )
  //       .then((response) => response.json())
  //       .then((body) => {
  //         if (body.result) {
  //           return body.result.map((item) => ({
  //             label: `${item.name}`,
  //             value: item.id,

  //           }));
  //         }
  //         return [];
  //       });
  //   },
  //   [props.token]
  // );

  const selectContragent = async (id) => {
    try {
      const response = await apiRequests.contragents.getItem(id);
      setContragent(response.data);
    } catch (e) {}
  };

  const handleSaveDocSale = (data) => {
    props.form.setFieldValue("docs_sales_id", data);
  };

  return (
    <Form form={props.form}>
      {/* <Form.Item name="name" label="Название">
        <Input />
      </Form.Item> */}
      <Form.Item name={"date_range"} label="Период брони">
        <RangePicker
          placeholder={["Начальная дата", "Конечная дата"]}
          format={"DD.MM.YYYY"}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item name={"sum_period"} label="Сумма за период">
        <Input type="number" addonAfter="₽" />
      </Form.Item>
      <Form.Item
        initialValue={"Не оплачен"}
        name={"status_doc_sales"}
        label="Статус оплаты"
      >
        <Select
          placeholder="Статус оплаты"
          options={[
            { value: "Оплачен", label: "Оплачен" },
            { value: "Не оплачен", label: "Не оплачен" },
            { value: "Частично оплачен", label: "Частично оплачен" },
          ]}
        />
      </Form.Item>
      <Form.Item
        initialValue={"Новый"}
        name={"status_booking"}
        label="Статус брони"
      >
        <Select
          placeholder="Статус брони"
          options={[
            { value: "Новый", label: "Новый" },
            { value: "Подтвержден", label: "Подтвержден" },
            { value: "Оплачен", label: "Оплачен" },
            { value: "Забран", label: "Забран" },
            { value: "Доставлен", label: "Доставлен" },
            { value: "Выгружен", label: "Выгружен" },
            { value: "Пролонгирован", label: "Пролонгирован" },
            { value: "Завершен", label: "Завершен" },
          ]}
        />
      </Form.Item>
      <Form.Item name={"date_app"} label="Дата заявки">
        <DatePicker style={{ width: "100%" }} format={"DD.MM.YYYY"} />
      </Form.Item>
      <Form.Item name={"driver"} label="Водитель">
        <DebounceSelect
          showSearch
          labelInValue
          allowClear="true"
          placeholder="Имя водителя"
          fetchOptions={fetchUsers}
          onChange={(data) => props.form.setFieldValue("driver", data.key)}
        />
      </Form.Item>
      {/* <Form.Item name={"nomenclature"} label="Номенклатура">
        <DebounceSelect
          showSearch
          labelInValue
          mode="multiple"
          onDeselect={calcPrice}
          allowClear="true"
          placeholder="Наименование номенклатуры"
          fetchOptions={fetchNomenclature}
          onBlur={calcPrice}
          onChange={(data) => {
            console.log(data)
            props.form.setFieldValue(
              "goods",
              data.map((item) => item.key)
            );
            props.form.setFieldValue(
              "nomenclature", data
            )
          }

          }
        />
      </Form.Item> */}
      <CreateDocsSalesWrapper
        token={props.token}
        updateRow={() => {}}
        onSave={handleSaveDocSale}
        tags={""}
        phone={contragent?.phone}
        name={contragent?.name}
      />
      <Form.Item name={"contragent"} label="Клиент">
        <ContragentAutocomplete
          api={`https://${process.env.REACT_APP_APP_URL}/api/v1/`}
          token={props.token}
          width="100%"
          // onSelect={(val,record) => {
          //   // selectContragent(record.value)
          //   // if (record) {
          //   //   props.form.setFieldValue(
          //   //     "contragent",
          //   //     record.value
          //   //   );
          //   // }
          // }}
          placeholder="Введите контрагента"
          labelInValue
          allowClear={true}
          onSelect={(obj) => {
            if (obj) {
              selectContragent(obj.value);
              props.form.setFieldValue("contragent", obj.value);
            }
          }}
        />
      </Form.Item>
      <Form.Item name={"contragent_accept"} label="Кто принимает">
        <ContragentAutocomplete
          api={`https://${process.env.REACT_APP_APP_URL}/api/v1/`}
          token={props.token}
          width="100%"
          placeholder="Введите контрагента"
          labelInValue
          allowClear={true}
          onSelect={(obj) => {
            if (obj) {
              props.form.setFieldValue("contragent_accept", obj.value);
            }
          }}
        />
      </Form.Item>
      <Form.Item initialValue={"Месяц"} name={"tariff"} label="Тариф">
        <Select
          placeholder="Выберите тариф"
          options={[{ value: "month", label: "Месяц" }]}
        />
      </Form.Item>
      <Form.Item name={"order"} label="Заказ">
        <DebounceSelect
          showSearch
          labelInValue
          allowClear="true"
          placeholder="Номер заказа"
          fetchOptions={fetchDocsSales}
          onChange={(data) => props.form.setFieldValue("order", data.key)}
        />
      </Form.Item>
      <Form.Item name={"address"} label="Адрес">
        <Input />
      </Form.Item>
      <Form.Item name={"comment"} label="Примечание">
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item name="docs_sales_id" noStyle />
    </Form>
  );
};

export default Rent;
