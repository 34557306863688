import { useState } from "react";
import { Card } from "antd";
import { useEffect } from "react";
import { TochkaButtonModal } from "src/enitities/Integration";

const { Meta } = Card;

export default function IntegrationTochkaBank({ query, websocket }) {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState({});

  useEffect(() => {}, []);

  return (
    <Card
      style={{ width: 250 }}
      cover={
        <div
          style={{
            display: "flex",
            height: 250,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "lightsalmon",
            color: "white",
          }}>
          <h3>Точка банк</h3>
        </div>
      }
      actions={[<TochkaButtonModal name="Установить виджет" query={query} websocket={websocket} />]}>
      <Meta title="Точка Банк" description={""} />
    </Card>
  );
}
