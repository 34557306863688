import React, { useContext, useState } from "react";
import { Prices } from "src/enitities/Table";
import { PricesContext } from "src/shared";
import { API } from "src/shared";
import { getFilterData } from "./lib/getFilterData";

export default function TablePrices() {
  const { token, pathname, websocket, params, countData } =
    useContext(PricesContext);
  const [dataSource, setDataSource] = useState();
  const [dataSourceFilter, setDataSourceFilter] = useState([]);

  const [loading, setLoading] = useState(false);
  const handleChanges = async (pagination, filters, sorter, extra) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    let newData = dataSource;
    setLoading(true);
    switch (extra.action) {
      case "filter":
        newData = await getFilterData(filters, pathname, params);
        break;
      default:
        newData = await getFilterData(filters, pathname, params);
    }
    setLoading(false);
    setDataSourceFilter(newData);
  };

  websocket.onmessage = async (message) => {
    const data = JSON.parse(message.data);
    if (data.target === "prices") {
      setDataSource(data);
    }
  };

  return (
    <Prices
      loading={loading}
      setLoading={setLoading}
      dataSource={dataSource}
      dataSourceFilter={dataSourceFilter}
      countData={countData}
      handleSave={API.crud.edit(token, pathname)}
      handleRemove={API.crud.remove(token, pathname)}
      handleChanges={handleChanges}
      token={token}
    />
  );
}
