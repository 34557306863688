import {Button, Popconfirm, Table} from "antd";
import React, {useContext} from "react";
import {DeleteOutlined} from "@ant-design/icons";
import {API} from "../../../../shared";
import {PriceTypesContent} from "../../../../shared/lib/hooks/context/getPriceTypesContext";



const TablePriceTypes = ({dataSource}) => {
    const {token, data, setData} = useContext(PriceTypesContent)

    const columns = [
        {
            title: 'Название',
            dataIndex: 'name',
        },
        {
            title: 'Действие',
            width: '10%',
            render: (_, record) => (
                <Popconfirm
                    title={"Подтвердите удаление"}
                    onConfirm={async () => {
                        const query = API.crud.remove(token, `/price_types`)
                        await query(record.id)
                            .then(i => {
                                setData(data.filter(i => i.id !== record.id))
                            })
                    }}
                >
                    <Button icon={<DeleteOutlined />} />
                </Popconfirm>
            )
        }
    ]

    return (
        <>
            <Table
                columns={columns}
                dataSource={data && data.slice().reverse()}
                style={{
                    marginTop: '20px'
                }}
            />
        </>
    )
}

export default TablePriceTypes