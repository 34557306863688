import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col, Space, Table, Checkbox } from "antd";
import { apiRequests } from "src/shared/api/api";

function TochkaButtonModal({ query, websocket }) {
  const [state, setState] = useState({
    idIntegration: 1,
    buttonLoad: true,
    blockButton: false,
    accountsLoad: false,
  });
  const [data, setData] = useState();
  const [accounts, setAccounts] = useState([]);

  const check = async () => {
    await apiRequests.integrationTochka
      .getIntegrationCheck({
        id_integration: state.idIntegration,
      })
      .then(async (res) => {
        console.log(data);
        setState((prev) => ({ ...prev, isAuth: res.data?.isAuth }));
        if (data?.action === "check") {
          setData((prev) => ({
            ...prev,
            integration_status: data?.integration_status,
          }));
        }
        if (res.data?.isAuth) {
          await apiRequests.integrationTochka
            .getIntegrationAccounts({
              id_integration: state.idIntegration,
            })
            .then((res) => {
              setAccounts(res.data.result);
              setState((prev) => ({ ...prev, accountsLoad: false }));
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
  };

  useEffect(() => {
    gettingData();
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gettingData = () => {
    websocket.onmessage = (e) => {
      const message = JSON.parse(e.data);
      console.log(message);
      setData(message);
    };
  };

  const showModal = () => {
    apiRequests.integrationTochka.getIntegrationOn({
      token: query?.token,
      id_integration: state.idIntegration,
    });
    setState({ ...state, isModalVisible: true });
  };

  const handleOk = () => {
    setState({ ...state, isModalVisible: false });
  };

  const handleCancel = () => {
    apiRequests.integrationTochka.getIntegrationOff({
      token: query?.token,
      id_integration: state.idIntegration,
    });
    setState({ ...state, isModalVisible: false, isAuth: data.isAuth });
  };

  return (
    <>
      <Button
        onClick={() => {
          check();
          showModal();
        }}
      >
        {!data?.integration_status ? "Установить виджет" : "Настройки"}
      </Button>
      <Modal
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        width={800}
        title={"Настройка: интеграция tablecrm.com и Точка Банк"}
        open={state.isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ danger: true, disabled: state.blockButton }}
        okButtonProps={{ disabled: state.blockButton }}
        cancelText="Отключить виджет"
        okText="Закрыть"
      >
        {state?.isAuth ? (
          <>
            Авторизаия выполнена успешно
            <Table
              loading={state.accountsLoad}
              dataSource={accounts}
              columns={[
                { title: "название", dataIndex: "name", key: "name" },
                { title: "валюта", dataIndex: "currency", key: "currency" },
                { title: "Тип", dataIndex: "accountType", key: "accountType" },
                {
                  title: "Активно",
                  dataIndex: "is_active",
                  key: "is_active",
                  render: (value, record) => (
                    <Checkbox
                      defaultChecked={value}
                      onChange={async ({ target }) => {
                        setState((prev) => ({ ...prev, accountsLoad: true }));
                        await apiRequests.integrationTochka
                          .patchIntegrationAccount(record.id, {
                            is_active: target.checked,
                          })
                          .then(async () => await check());
                      }}
                    />
                  ),
                },
              ]}
            />
          </>
        ) : (
          <>
            <Space size={"small"} direction="vertical">
              <Row>
                <Col>
                  Необходимо пройти аутентификацию. Вас перенаправит на
                  странницу авторизации tochkabank.ru. Выберите компании и
                  разрешения для получения дпнных в tablecrm.com
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    loading={!state.buttonLoad}
                    onClick={async () => {
                      setState((prev) => ({
                        ...prev,
                        buttonLoad: true,
                        blockButton: true,
                      }));
                      await apiRequests.integrationTochka
                        .getLink({
                          token: query?.token,
                          id_integration: state.idIntegration,
                        })
                        .then((res) => {
                          setState((prev) => ({
                            ...prev,
                            buttonLoad: false,
                            blockButton: false,
                          }));
                          return res;
                        })
                        .then((res) => {
                          window.location.replace(res?.data?.link);
                        })
                        .catch(() => {
                          setState((prev) => ({
                            ...prev,
                            buttonLoad: false,
                            blockButton: false,
                          }));
                        });
                    }}
                  >
                    выполнить
                  </Button>
                </Col>
              </Row>
            </Space>
          </>
        )}
      </Modal>
    </>
  );
}

export default TochkaButtonModal;
