import { AutoComplete, Spin, message } from "antd";
import { useEffect, useState } from "react";
import AddContragentDrawer from "../../features/Modal/ui/AddContragentDrawer";
import EditContragentDrawer from "../../features/Modal/ui/EditContragentDrawer";
import debounce from "../lib/utils/debounce";
import { apiRequests } from "./api";

const ContragentAutocomplete = (props) => {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [fetching, setFetching] = useState(false);

  const handleSearch = async (value) => {
    let result = [];
    setFetching(true);

    try {
      if (!value) {
        result = await apiRequests.contragents.getItems();
        message.success("Показаны все контрагенты");
      } else if (!isNaN(Number(value))) {
        message.loading("Поиск по ИНН...", 0);
        const innPromise = apiRequests.contragents.getItems({ inn: value });
        message.loading("Поиск по телефону...", 0);
        const phonePromise = apiRequests.contragents.getItems({ phone: value });

        const [innResult, phoneResult] = await Promise.all([
          innPromise,
          phonePromise,
        ]);

        const innData = innResult.data.result || [];
        const phoneData = phoneResult.data.result || [];

        message.destroy();
        if (innData.length > 0) {
          message.success("Найдены совпадения по ИНН");
        } else {
          message.info("Совпадений по ИНН не найдено");
        }

        if (phoneData.length > 0) {
          message.success("Найдены совпадения по телефону");
        } else {
          message.info("Совпадений по телефону не найдено");
        }

        const combinedData = [
          ...new Map(
            [...innData, ...phoneData].map((item) => [item.id, item])
          ).values(),
        ];

        result = { data: { result: combinedData } };
      } else {
        message.loading("Поиск по имени...", 0);
        result = await apiRequests.contragents.getItems({ name: value });

        message.destroy();
        if (result.data.result.length > 0) {
          message.success("Найдены совпадения по имени");
        } else {
          message.info("Совпадений по имени не найдено");
        }
      }
    } catch (error) {
      message.error("Произошла ошибка при выполнении поиска");
    } finally {
      const options = result.data.result.map((contragent) => ({
        label: `${contragent.name}`,
        value: contragent.id.toString(),
      }));

      setOptions(options);
      setFetching(false);
    }
  };

  const SearchHandler = debounce(handleSearch, 650);

  const insertContragent = (value) => {
    const contragent = {
      key: value.id,
      label: `${value.name}`,
      value: `${value.name}`,
    };
    props.onSelect(value.id);
    const newOptions = [...options].filter((i) => i.value !== contragent.key);
    setOptions([contragent, ...newOptions]);
    setValue(contragent);
  };

  const handleClear = () => {
    console.log("clear");
    props.onSelect(null);
    setValue(null);
  };

  useEffect(() => {
    if (props.contragent) {
      setValue({
        key: props.contragent.id,
        label: props.contragent.name,
        value: props.contragent.name,
      });
    } else {
      setValue(null);
    }
  }, [props.contragent]);

  useEffect(() => {
    handleSearch("");
  }, []);

  return (
    <>
      {props.forType === "docs_sales" ? (
        <>
          <AutoComplete
            {...props}
            mode=""
            allowClear
            onClear={handleClear}
            options={options}
            value={value}
            onChange={(val) => setValue(val)}
            onSelect={(val) => props.onSelect(val)}
            onSearch={SearchHandler}
            style={{ width: props.width ? props.width : 300 }}
          />
          {props.contragent && (
            <EditContragentDrawer
              token={props.token}
              abra={props.contragent.id}
              contragent={value}
              updateField={insertContragent}
            />
          )}
          {!options.length && (
            <AddContragentDrawer
              insertContragent={insertContragent}
              value={value}
              token={props.token}
            />
          )}
        </>
      ) : (
        <AutoComplete
          {...props}
          onSearch={SearchHandler}
          onSelect={(val) => {
            // props.onSelectRent(record.label)
            props.onSelect(val);
          }}
          showSearch
          allowClear
          onClear={handleClear}
          onChange={(val) => {
            setValue(val);
          }}
          style={{ width: props.width ? props.width : 300 }}
          options={options}
          value={value}
          notFoundContent={
            fetching ? (
              <Spin size="small" />
            ) : (
              <div style={{ textAlign: "center" }}>
                При поиске контрагента по <br />
                <span style={{ fontWeight: "bold", letterSpacing: 0.5 }}>
                  Имени / ИНН / Телефону <br />
                </span>
                ничего не найдено.
              </div>
            )
          }
        />
      )}
    </>
  );
};

export default ContragentAutocomplete;
