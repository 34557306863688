// модальное окно добавления счета на странице "Счета"
import React from "react";
import { Modal, Button, Form, Input, InputNumber, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { apiRequests } from "src/shared/api/api";

const layout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 20,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 15,
  },
};

const validateMessages = {
  /* eslint-disable no-template-curly-in-string */
  required: "${label} обязательно!",
};

class NewPboxModal extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      isModalVisible: false,
      changeData: false,
      isRequestLoading: false,
      init_values: {
        name: null,
        start_balance: null,
      },
    };
  }
  render() {
    const showModal = () => {
      this.setState({ isModalVisible: true, changeData: false });
    };

    const handleOk = (data) => {
      this.setState({ changeData: false, isRequestLoading: true });

      apiRequests.payboxes.post(data)
        .then((response) => {
          message.success(<>Вы успешно создали счет</>);
          this.setState({isModalVisible: false})
        })
          .finally(() => this.setState({isRequestLoading: false}))
      ;
    };

    const handleState = () => {
      this.setState({ isModalVisible: false });
    };

    const handleCancel = () => {
      this.state.changeData === true
        ? Modal.confirm({
            title: "Подтвердите действие",
            content: "Вы дейтвительно хотите закрыть окно?",
            okText: "Да",
            cancelText: "Нет",
            onOk: () => {
              handleState();
            },
          })
        : handleState();
    };

    const onValuesChange = (changedValues, allValues) => {
      this.setState({ changeData: true });
    };

    return (
      <>
        <Button
          style={{ marginBottom: 15 }}
          icon={<PlusOutlined />}
          type="primary"
          onClick={showModal}
        >
          Добавить счет
        </Button>
        <Modal
          destroyOnClose={true}
          footer={null}
          title="Добавить новый счет"
          open={this.state.isModalVisible}
          onCancel={handleCancel}
        >
          <Form
            {...layout}
            ref={this.formRef}
            name="control-ref"
            style={{pointerEvents: `${this.state.isRequestLoading === true ? 'none': ''}`}}
            onValuesChange={onValuesChange}
            validateMessages={validateMessages}
            onFinish={handleOk}
            initialValues={this.state.init_values}
          >
            <Form.Item
              name="name"
              label="Название счета"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input style={{ width: 250 }}></Input>
            </Form.Item>

            <Form.Item
              name="start_balance"
              label="Начальный остаток"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                step={0.01}
                precision={2}
                min={0}
                style={{ width: 250 }}
              />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: 5 }}
                loading={this.state.isRequestLoading}
              >
                Подтвердить
              </Button>
              <Button htmlType="button" onClick={handleCancel}>
                Отмена
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default NewPboxModal;
