import React, {useEffect, useState} from "react";
import { TableLoyalityReport } from "src/features/Table";
import {currentMonthRange, LoyalityReportContext} from "src/shared";
import { useLocation } from "react-router-dom";
import {apiRequests} from "../../../shared/api/api";
import {message, Spin} from "antd";

export default function TableCategoriesPage({
  token,
  websocket,
  params,
}) {
    const { pathname } = useLocation();
    const [isLoading, setLoading] = useState(false)
    const [initialData, setInitialData] = useState()
    const getInitialData = async () => {
        const {date_from, date_to} = currentMonthRange()
        setLoading(true)
        await apiRequests.analytics.getCards({date_from, date_to})
            .then((res) => {
                setInitialData(res.data)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                message.error('Произошла ошибка, пожалуйста повторите позже')
            })
    }

    useEffect(() => {
        getInitialData()
    }, []);

  return (
    <>
      <LoyalityReportContext.Provider
        value={{
          token,
          params,
          initialData,
          pathname,
          websocket,
        }}
      >
          {
              isLoading ? <Spin /> : <TableLoyalityReport />
          }
      </LoyalityReportContext.Provider>
    </>
  );
}
