import React, { useContext, useState } from "react";
import { Form } from "antd";
import { ModalForm } from "src/enitities/Modal";
import { WarehousesDocsForm, serializeTimestamp } from "src/enitities/Form";
import { WarehousesDocsContext } from "src/shared";
import { API } from "../../Table";

export default function AddWarehousesDocs({ isOpen, setOpen }) {
  const [changeData, setChangeData] = useState(false);
  const { token, pathname } = useContext(WarehousesDocsContext);
  const [form] = Form.useForm();
  const [formTable] = Form.useForm();

  const handleSubmit = async (data) => {
    const queryCreate = API.crud.create(token, pathname);
    const newData = serializeTimestamp(data[0], "dated");
    try {
      await queryCreate([newData]);
      return true;
    } catch (err) {
      return Promise.reject(err.response?.data.detail[0].msg);
    }
  };

  return (
    <ModalForm
      title={"Добавить документ"}
      width={1800}
      isOpen={isOpen}
      setOpen={setOpen}
      formContext={form}
      handleSubmit={handleSubmit}
      setChangeData={setChangeData}
      changeData={changeData}
    >
      <WarehousesDocsForm
        formContext={form}
        formTableContext={formTable}
        setChangeData={setChangeData}
      />
    </ModalForm>
  );
}
