import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col, Space, Form, Input, Typography } from "antd";
import { apiRequests } from "src/shared/api/api";

function EvotorButtonModal({ query, websocket }) {
  const [state, setState] = useState({
    idIntegration: 2,
    buttonLoad: false,
    blockButton: false,
    accountsLoad: false,
    errorFinish: "",
  });
  const [data, setData] = useState();

  const check = async () => {
    await apiRequests.integrationEvotor
      .getIntegrationCheck({
        id_integration: state.idIntegration,
      })
      .then(async (res) => {
        console.log(data);
        setState((prev) => ({ ...prev, isAuth: res.data?.isAuth }));
        if (data?.action === "check") {
          setData((prev) => ({
            ...prev,
            integration_status: data?.integration_status,
          }));
        }
      });
  };

  useEffect(() => {
    gettingData();
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gettingData = () => {
    websocket.onmessage = (e) => {
      const message = JSON.parse(e.data);
      console.log(message);
      setData(message);
    };
  };

  const showModal = () => {
    apiRequests.integrationEvotor.getIntegrationOn({
      token: query?.token,
      id_integration: state.idIntegration,
    });
    setState({ ...state, isModalVisible: true });
  };

  const handleOk = () => {
    setState({ ...state, isModalVisible: false, errorFinish: "" });
  };

  const handleCancel = () => {
    apiRequests.integrationEvotor.getIntegrationOff({
      token: query?.token,
      id_integration: state.idIntegration,
    });
    setState({
      ...state,
      isModalVisible: false,
      isAuth: false,
      errorFinish: "",
    });
  };

  const handleFinish = async ({ token }) => {
    setState((prev) => ({ ...prev, buttonLoad: true }));
    apiRequests.integrationEvotor
      .getIntegrationInstall({
        token: query?.token,
        evotor_token: token,
        id_integration: state.idIntegration,
      })
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        setState((prev) => ({ ...prev, buttonLoad: false }));
        setState((prev) => ({
          ...prev,
          errorFinish: "приложение не установлено в Эвотор",
        }));
      })
      .finally(() => {
        setState((prev) => ({ ...prev, buttonLoad: false }));
        check();
      });
  };

  return (
    <>
      <Button
        onClick={() => {
          check();
          showModal();
        }}
      >
        {!data?.integration_status ? "Установить виджет" : "Настройки"}
      </Button>
      <Modal
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        width={800}
        title={
          "Настройка: интеграция tablecrm.com и Эвотор: системы лояльности"
        }
        open={state.isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ danger: true, disabled: state.blockButton }}
        okButtonProps={{ disabled: state.blockButton }}
        cancelText="Отключить виджет"
        okText="Закрыть"
      >
        {state?.isAuth ? (
          <Typography style={{ color: "blue" }}>
            Авторизация с приложением Эвотор: система лояльности успешно
            выполнена
          </Typography>
        ) : (
          <>
            <Space size={"small"} direction="vertical">
              <Row>
                <Col>Необходимо пройти аутентификацию.</Col>
              </Row>
              <Row>
                <Col>
                  <Form onFinish={handleFinish}>
                    <Form.Item
                      label="Токен приложения (в Эвотор)"
                      name="token"
                      rules={[{ required: true }]}
                    >
                      <Input style={{ width: 350 }} />
                    </Form.Item>
                    <Form.Item>
                      <Button htmlType="submit" loading={state.buttonLoad}>
                        выполнить
                      </Button>
                      <Typography style={{ color: "red" }}>
                        {state.errorFinish}
                      </Typography>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Space>
          </>
        )}
      </Modal>
    </>
  );
}

export default EvotorButtonModal;
