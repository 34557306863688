import React, { useState, useEffect, useContext } from "react";
import { PricesContext } from "src/shared";
import { Form, InputNumber, Select, DatePicker } from "antd";
import { DebounceSelect } from "src/shared/lib/hooks/context/DebounceSelect";
import dayjs from "dayjs";

// eslint-disable-next-line no-template-curly-in-string
const templateRule = "Поле обязательно к заполнению";

export default function Price({ formContext, record, disableNomeclature }) {
  const formItemLayout = { labelCol: { span: 7 }, wrapperCol: { span: 15 } };
  const { priceTypesData, token } = useContext(PricesContext);
  const [priceTypes, setPriceType] = useState([]);

  const fetchNomenclature = async (name) => {
    return fetch(`https://${process.env.REACT_APP_APP_URL}/api/v1/nomenclature/?token=${token}&name=${name}`)
      .then((response) => response.json())
      .then((body) => {
        return body;
      })
      .then((body) => {
        if (body.result) {
          return body.result.map((item) => ({
            label: `${item.name}`,
            value: item.id,
          }));
        }
      })
      .then((body) => {
        return body;
      });
  };

  useEffect(() => {
    if (priceTypesData?.length !== 0) {
      const priceTypeSelect = [];
      for (let item of priceTypesData) {
        const valueObj = { key: item.id, value: item.id, label: item.name };
        if (typeof record !== "undefined" && item.name === record.price_type)
          formContext.setFieldValue("price_type", item.id);
        priceTypeSelect.push(valueObj);
      }
      setPriceType(priceTypeSelect);
    }
  }, [priceTypesData, record, formContext]);

  return (
    <Form
      {...formItemLayout}
      form={formContext}
      // initialValues={parseTimestamp([record], ["date_from", "date_to"])}
      layout={"horizontal"}
      style={{ maxWidth: "100%" }}
      // onValuesChange={onValuesChange}
    >
      <Form.Item
        label={"Номенклатура"}
        name={"nomenclature"}
        initialValue={record?.nomenclature_name}
        rules={[{ required: true, message: templateRule }]}>
        <DebounceSelect
          showSearch
          disabled={disableNomeclature}
          labelInValue
          allowClear="true"
          placeholder="Введите номенклатуру"
          fetchOptions={fetchNomenclature}
          onChange={(data) => formContext.setFieldValue("nomenclature", data.key)}
        />
      </Form.Item>
      <Form.Item
        initialValue={record?.price}
        label={"Цена"}
        name={"price"}
        rules={[{ required: true, message: templateRule }]}>
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item label={"Тип цены"} name={"price_type"} rules={[{ required: true, message: templateRule }]}>
        <Select options={priceTypes} />
      </Form.Item>
      <Form.Item
        label={"Действует от"}
        name={"date_from"}
        initialValue={record?.date_from ? dayjs.unix(record?.date_from) : dayjs()}>
        <DatePicker format={{ format: "DD.MM.YYYY", type: "mask" }} />
      </Form.Item>
      <Form.Item
        label={"Действует до"}
        name={"date_to"}
        initialValue={record?.date_from ? dayjs.unix(record?.date_from) : dayjs()}>
        <DatePicker format={{ format: "DD.MM.YYYY", type: "mask" }} />
      </Form.Item>
    </Form>
  );
}
