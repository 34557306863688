import React from "react";
import { Modal, message } from "antd";

export default function ModalForm({
  formContext,
  width,
  children,
  title,
  isOpen,
  setOpen,
  handleSubmit,
  submitIsDisabled,
  changeData,
  setChangeData,
}) {
  const handleOk = () => {
    formContext
      .validateFields()
      .then(async (values) => {
        await handleSubmit([values]);
        setOpen(false);
      })
      .catch((info) => {
        console.info(info);
        switch (info) {
          case "field required":
            message.error("Вы не заполнили обязательные поля");
            break;
          case "Цена с таким типом уже существует":
            message.error("Цена с таким типом уже существует");
            break;
          case "value is not a valid integer":
          case "value is not a valid float":
            message.error("Значение не является допустимым числом");
            break;
          default:
            message.error(
              "Упс... Произошла необработанная ошибка. Попробуйте ещё раз"
            );
            break;
        }
      });
    setChangeData(false);
  };

  const handleCancel = () => {
    changeData === true
      ? Modal.confirm({
          title: "Подтвердите действие",
          content: "Вы дейтвительно хотите закрыть окно?",
          okText: "Да",
          cancelText: "Нет",
          onOk: () => {
            setOpen(false);
          },
        })
      : setOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      // centered={true}
      // forceRender
      width={width || 800}
      title={title}
      onOk={() => handleOk()}
      okButtonProps={{
        disabled: submitIsDisabled,
      }}
      onCancel={() => handleCancel()}
      okText={"Подтвердить"}
      cancelText={"Отмена"}
    >
      {children}
    </Modal>
  );
}
