import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { OrganizationsContext } from "src/shared";
import { AddOrganizationButton } from "src/widgets/Button";
import { TableOrganizations } from "src/features/Table";

export default function TableOrganizationsPage({
  token,
  websocket,
  initialData,
}) {
  const [data, setData] = useState(initialData);
  useEffect(() => {
    if (initialData) {
      setData(initialData);
    }
  }, [initialData]);

  const { pathname } = useLocation();
  return (
    <OrganizationsContext.Provider value={{ token, websocket, data, pathname }}>
      <AddOrganizationButton />
      <TableOrganizations />
    </OrganizationsContext.Provider>
  );
}
