import React, { useState, lazy, Suspense } from "react";
import { Form, Button } from "antd";
import { ModalForm } from "src/enitities/Modal";
import { serializeTimestamp } from "src/enitities/Form";
import { EditOutlined } from "@ant-design/icons";
const LoyalitySettingForm = lazy(() =>
  import("src/enitities/Form").then((modal) => ({
    default: modal.LoyalitySettingForm,
  }))
);

export default function EditLoyalitySetting({ record, handleSave }) {
    const [lifetimeType] = useState('Минуты')
    const [isOpen, setOpen] = useState(false);
    const [changeData, setChangeData] = useState(false);
    const [form] = Form.useForm();
  const handleSubmit = async (data) => {
      const lifetime = Number(data[0].lifetime)
    const newData = serializeTimestamp(data[0], ["end_period", "start_period"]);
      switch (lifetimeType) {
          case 'Минуты':
              newData.lifetime = lifetime * 60
              break;
          case 'Дни':
              newData.lifetime = lifetime * 86400
              break;
          case 'Недели':
              newData.lifetime = lifetime * (86400 * 7)
              break;
          default:
              break;
      }
    newData.id = record.id;
    try {
      await handleSave(newData);
      return true;
    } catch (err) {
      return Promise.reject(err.message.detail);
    }
  };

  return (
    <>
      <Button
        icon={<EditOutlined />}
        onClick={() => setOpen(true)}
        style={{ marginRight: "5px" }}
      />
      <ModalForm
        width={600}
        isOpen={isOpen}
        setOpen={setOpen}
        formContext={form}
        handleSubmit={handleSubmit}
        setChangeData={setChangeData}
        changeData={changeData}
      >
        {isOpen ? (
          <Suspense fallback={<div></div>}>
            <LoyalitySettingForm
              formContext={form}
              record={record}
              setChangeData={setChangeData}
            />
          </Suspense>
        ) : null}
      </ModalForm>
    </>
  );
}
