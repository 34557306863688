import AddPriceType from "../../../widgets/Button/ui/AddPriceType";
import {PriceTypesContent} from "../../../shared/lib/hooks/context/getPriceTypesContext";
import TablePriceTypes from "../../../features/Table/ui/TablePriceTypes/TablePriceTypes";
import {useEffect, useState} from "react";


const TablePriceType = ({token, priceTypeData, setPriceType}) => {
    const [data, setData] = useState(priceTypeData.priceTypeDS)

    useEffect(() => {
        if (priceTypeData) setData(priceTypeData.priceTypeDS)
    }, [priceTypeData]);

    return (
        <PriceTypesContent.Provider
            value={{
                token,
                setData,
                priceTypeData,
                setPriceType,
                data
            }}
        >
            <AddPriceType />
            <TablePriceTypes />
        </PriceTypesContent.Provider>
    )
}


export default TablePriceType