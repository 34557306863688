// страница "Категории"
import React, { useState, useEffect } from "react";
import AddRowButton from "src/widgets/Button/ui/AddRowButton";
import TableCategories from "src/features/Table/ui/TableCategories";
import { CategoriesContext } from "src/shared/lib/hooks/context/getCategoriesContext";
import { useLocation } from "react-router-dom";

export default function TableCategoriesPage({ token, websocket, initialData }) {
  const { pathname } = useLocation();

  const [data, setData] = useState(initialData);

  useEffect(() => {
    if (initialData) {
      setData(initialData);
    }
  }, [initialData]);

  return (
    <>
      <CategoriesContext.Provider value={{ token, data, pathname, websocket }}>
        <AddRowButton />
        <TableCategories />
      </CategoriesContext.Provider>
    </>
  );
}
