import React, { useContext, useState } from "react";
import { Form } from "antd";
import { ModalForm } from "src/enitities/Modal";
import { LoyalitySettingForm, serializeTimestamp } from "src/enitities/Form";
import { LoyalitySettingContext } from "src/shared";
import { API } from "src/shared";

export default function AddLoyalitySetting({ isOpen, setOpen }) {
  const [changeData, setChangeData] = useState(false);
  const { token, pathname } = useContext(LoyalitySettingContext);
  const [form] = Form.useForm();
  const [lifetimeType, setLifetimeType] = useState('Минуты')

  const handleType = (val) => setLifetimeType(val);


  const handleSubmit = async (data) => {
    let lifetime = Number(data[0].lifetime)

    const queryCreate = API.crud.create(token, pathname);
    const newData = serializeTimestamp(data[0], ["start_period", "end_period"]);
    switch (lifetimeType) {
      case 'Минуты':
        newData.lifetime = lifetime * 60
        break;
      case 'Дни':
        newData.lifetime = lifetime * 86400
        break;
      case 'Недели':
        newData.lifetime = lifetime * (86400 * 7)
        break;
      default:
        break;
    }
    try {
      await queryCreate(newData);
      return true;
    } catch (err) {
      return Promise.reject(err.message.detail);
    }
  };

  return (
    <ModalForm
      title={"Добавить настройки к карте"}
      width={600}
      isOpen={isOpen}
      setOpen={setOpen}
      formContext={form}
      handleSubmit={handleSubmit}
      changeData={changeData}
      setChangeData={setChangeData}
    >
      <LoyalitySettingForm handleType={handleType} formContext={form} setChangeData={setChangeData} />
    </ModalForm>
  );
}
