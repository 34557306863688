import useMessage from "antd/es/message/useMessage";
import {TablePayments} from "../index";


const PaymentsWrapper = (props) => {
    const [messageApi, contextHolder] = useMessage()

    return (
        <>
            {contextHolder}
            <TablePayments messageApi={messageApi} {...props} />
        </>
    )
}

export default PaymentsWrapper