import React from "react";
import { Button, Input, Modal, Popover, Space, Table, Tag } from "antd";
import _ from "lodash";

export const searchResult = async (api, token, query, ts, in_warehouse) => {
  let type = ts ? "barcode" : "name";
  return fetch(
    `${api}nomenclature/?token=${token}&${type}=${query}&with_prices=true&with_balance=true&in_warehouse=${in_warehouse?.id}&limit=100000
    `
  )
    .then((response) => response.json())
    .then((body) =>
      body.result.map((user) => ({
        label: `${user.name}`,
        value: user.id,
      }))
    );
};

const searchResultByCategory = async (
  api,
  token,
  category,
  nom_name,
  in_warehouse
) => {
  let url = `${api}nomenclature/?token=${token}&category=${category}&with_prices=true&with_balance=true&in_warehouse=${
    in_warehouse?.id || 0
  }&limit=100000
  `;
  if (nom_name !== "") {
    url += `&name=${nom_name}`;
  }
  return fetch(url)
    .then((response) => response.json())
    .then((body) =>
      body.result.map((user) => ({
        name: `${user.name}`,
        key: user.id + 9999,
        unit_name: user.unit_name,
        table_id: user.id,
        description_short: user.description_short,
        action: true,
        barcodes: { barcodes: user?.barcodes, type: "child" },
        prices: { prices: user?.prices, type: "child" },
        balances: { balances: user?.balances, type: "child" },
      }))
    );
};

// const searchResultByName = async (api, token, name) => {
//   return fetch(`${api}nomenclature/?token=${token}&name=${name}`)
//     .then((response) => response.json())
//     .then((body) =>
//       body.result.map((user) => ({
//         name: `${user.name}`,
//         key: user.id + 9999,
//         unit_name: user.unit_name,
//         table_id: user.id,
//         category: user.category,
//         description_short: user.description_short,
//         action: true
//       }))
//     );
// };

const searchResultCategory = async (api, token, nom_name) => {
  let url = `${api}categories_tree/?token=${token}`;
  if (nom_name !== "") {
    url += `&nomenclature_name=${nom_name}`;
  }
  return fetch(url)
    .then((response) => response.json())
    .then((body) => body.result);
};

class NomAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      typeSearch: false,
      isModalOpen: false,
      nomDS: [],
      loading: false,
      nom_name: "",
      expandedRows: [],
      search: "",
    };
    this.handleChange = this.handleChange.bind(this);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    this.debouncedHandler = _.debounce(this.handleChangeSearch.bind(this), 800);

    this.columns = [
      {
        title: "Наименование",
        key: "name",
        dataIndex: "name",
        render: (_, record) => {
          if (record?.nom_count > 0) {
            return `${record?.name} (${record?.nom_count})`;
          } else {
            return `${record?.name}`;
          }
        },
      },
      {
        title: `Цены`,
        key: "prices",
        dataIndex: "prices",
        render: (values) => {
          if (values?.type === "child") {
            if (values?.prices?.length > 0) {
              if (values?.prices?.length < 2) {
                return values?.prices?.map((item) => {
                  return (
                    <Tag>
                      {item?.price_type}: {item?.price}
                    </Tag>
                  );
                });
              } else {
                return (
                  <>
                    <Space size={"small"} direction={"vertical"}>
                      <Tag color="green">
                        {values?.prices[0]?.price_type}:{" "}
                        {values?.prices[0]?.price}
                      </Tag>
                      <Popover
                        title={"Теги"}
                        content={
                          <>
                            {values?.prices
                              ?.slice(1, values?.prices?.length)
                              .map((tag) => {
                                return (
                                  <Space size={"small"}>
                                    <Tag color="green" key={tag}>
                                      {tag?.price_type}: {tag?.price}
                                    </Tag>
                                  </Space>
                                );
                              })}
                          </>
                        }
                      >
                        <Tag color="red">+{values?.prices?.length - 1}</Tag>
                      </Popover>
                    </Space>
                  </>
                );
              }
            }
          }
        },
      },
      {
        title: `Остатки`,
        key: "balances",
        dataIndex: "balances",
        render: (values) => {
          if (values?.type === "child") {
            if (values?.balances?.length > 0) {
              if (values?.balances?.length < 2) {
                return values?.balances?.map((item) => {
                  return (
                    <Tag>
                      {item?.warehouse_name}: {item?.current_amount}
                    </Tag>
                  );
                });
              } else {
                return (
                  <>
                    <Tag color="green">
                      {values?.balances[0]?.warehouse_name}:{" "}
                      {values?.balances[0]?.current_amount}
                    </Tag>
                    <Popover
                      title={"Теги"}
                      content={
                        <>
                          {values?.balances
                            ?.slice(1, values?.balances?.length)
                            .map((tag) => {
                              return (
                                <Tag color="green" key={tag}>
                                  {tag?.warehouse_name}: {tag?.current_amount}
                                </Tag>
                              );
                            })}
                        </>
                      }
                    >
                      <Tag color="red">+{values?.balances?.length - 1}</Tag>
                    </Popover>
                  </>
                );
              }
            }
          }
        },
      },
      {
        title: "Единица",
        key: "unit_name",
        dataIndex: "unit_name",
      },
      {
        title: "ШК",
        key: "barcodes",
        dataIndex: "barcodes",
        render: (value) => {
          if (value?.type === "child") {
            if (value?.barcodes?.length > 0) {
              if (value?.barcodes?.length < 2) {
                return value?.barcodes?.map((item) => {
                  if (item) {
                    return (
                      <Tag key={item} color="success">
                        {item}
                      </Tag>
                    );
                  } else {
                    return (
                      <Tag key={value} color="error">
                        нет штрихкода
                      </Tag>
                    );
                  }
                });
              } else {
                return (
                  <>
                    <Tag color="green">{value?.barcodes[0]?.toUpperCase()}</Tag>
                    <Popover
                      title={"Теги"}
                      content={
                        <>
                          {value?.barcodes
                            ?.slice(1, value?.barcodes?.length)
                            .map((tag) => {
                              return (
                                <Tag color="green" key={tag}>
                                  {tag.toUpperCase()}
                                </Tag>
                              );
                            })}
                        </>
                      }
                    >
                      <Tag color="red">+{value?.barcodes?.length - 1}</Tag>
                    </Popover>
                  </>
                );
              }
            } else {
              return (
                <Tag key={value} color="error">
                  нет штрихкода
                </Tag>
              );
            }
          }
        },
      },
      {
        title: "Действие",
        key: "action",
        dataIndex: "action",
        render: (action, record) => {
          if (action === true) {
            return (
              <Button
                type="primary"
                onClick={() => {
                  this.props.onSelect(record.table_id);
                  this.setState({ isModalOpen: false });
                }}
              >
                Выбрать
              </Button>
            );
          }
        },
      },
    ];
  }

  async componentDidMount() {
    // await this.handleSearch("");
  }

  handleChangeSearch = (e) => {
    this.handleChange();
    this.setState({ loading: true });
    searchResultCategory(this.props.api, this.props.token, e.target.value).then(
      (res) => this.setState({ nomDS: res, loading: false })
    );
    this.setState({ nom_name: e.target.value });
  };

  handleRowExpand(record) {
    console.log(record);
    this.setState((prevState) =>
      prevState.expandedRows.includes(record.key)
        ? {
            expandedRows: prevState.expandedRows.filter(
              (key) => key !== record.key
            ),
          }
        : { expandedRows: [...prevState.expandedRows, record.key] }
    );
  }

  handleChange = () => {
    this.setState({ expandedRows: [] });
  };

  onInputName = (name) => {
    this.setState({ search: name });
    // this.handleChange();
    // searchResultCategory(this.props.api, this.props.token, name).then((res) => this.setState({ nomDS: res }));
    // this.setState({ nom_name: name });
  };

  handleSearch = async (value) => {
    const option = await searchResult(
      this.props.api,
      this.props.token,
      value,
      this.state.typeSearch,
      this.props.warehouse
    );
    this.setState({
      options: option,
    });
  };

  handlePaste = () => {
    this.setState({ typeSearch: true });
  };

  showModal = async () => {
    this.setState({ isModalOpen: true, loading: true });
    await searchResultCategory(this.props.api, this.props.token, "").then(
      (res) =>
        this.setState({
          nomDS: res,
          loading: false,
        })
    );
  };

  handleOk = () => {
    this.setState({ isModalOpen: false });
  };

  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };

  walk = (collection, item_id) => {
    // return collection.find(item => item.key === item_id || this.walk(item.children, item_id));
    for (const item of collection) {
      if (item.key === item_id) return item;
      if (item.children) {
        const result = this.walk(item.children, item_id);
        if (result) return result;
      }
    }
    return null;
  };

  render() {
    // const { options } = this.state;

    return (
      // <AutoComplete
      //   {...this.props}
      //   mode=""
      //   options={options}
      //   onSearch={this.handleSearch}
      //   onChange={() => {
      //     this.setState({ typeSearch: false });
      //   }}
      //   onPaste={this.handlePaste}
      // ></AutoComplete>
      <>
        <Button
          style={
            this.props.buttonIntegrated
              ? { borderRadius: "8px 0 0 8px", width: "100%" }
              : {}
          }
          type="primary"
          onClick={this.showModal}
        >
          Выбрать
        </Button>
        <Modal
          destroyOnClose
          width={1500}
          title="Выбор номенклатуры"
          open={this.state.isModalOpen}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Input onChange={this.debouncedHandler} />

          <Table
            columns={this.columns}
            loading={this.state.loading}
            dataSource={[...this.state.nomDS]}
            expandable={{
              expandedRowKeys: this.state.expandedRows,
              defaultExpandedRowKeys: this.state.expandedRows,
              indentSize: 50,
              onExpand: (expanded, record) => {
                this.handleRowExpand(record);
                if (expanded === true) {
                  if (record.expanded_flag === false) {
                    this.setState({ loading: true });
                    const newData = [...this.state.nomDS];
                    let item = this.walk(newData, record.key);

                    searchResultByCategory(
                      this.props.api,
                      this.props.token,
                      record.key,
                      this.state.nom_name,
                      this.props.warehouse
                    ).then((res) => {
                      console.log(res);
                      item.children = record.children.concat(res);
                      item.expanded_flag = true;
                      this.setState({ loading: false });
                      this.setState({ nomDS: newData });
                    });
                  }
                }
              },
            }}
          ></Table>
        </Modal>
      </>
    );
  }
}

export default NomAutocomplete;
