import { useQuery } from "react-query";
import { useFetchAllData } from "src/shared/lib/hooks/context";
import { apiRequests } from "src/shared/api/api";

export const useFetchGetCategories = (options) => {
  const { token, name, } = options;
  const query = useQuery(
    ["categories", token, name],
    async () => {
      const response = await apiRequests.categories.getItems({ name });
      return response.data.result;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return query;
};

export const useFetchAllCategories = ({ token }) =>
  useFetchAllData({ token, key: "categories", path: "categories/" });
