import { useState } from "react";
import { Card } from "antd";
import { useEffect } from "react";
import { EvotorButtonModal } from "src/enitities/Integration";

const { Meta } = Card;

export default function IntegrationEvotor({ query, websocket }) {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState({});

  useEffect(() => {}, []);

  return (
    <Card
      style={{ width: 250 }}
      cover={
        <div
          style={{
            display: "flex",
            height: 250,
            justifyContent: "center",
            alignItems: "center",
            background:
              "linear-gradient(198deg, rgba(240,80,35,1) 0%, rgba(45,111,142,1) 52%, rgba(244,246,248,1) 100%)",
            color: "white",
          }}>
          <h3>Эвотор: система лояльности</h3>
        </div>
      }
      actions={[<EvotorButtonModal name="Установить виджет" query={query} websocket={websocket} />]}>
      <Meta title="Эвотор: система лояльности" description={""} />
    </Card>
  );
}
