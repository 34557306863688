import { useQuery, useMutation } from "react-query";
import { apiRequests } from "src/shared/api/api";
import { useFetchAllData } from "src/shared/lib/hooks/context";

export const useFetchGetNomenclature = (options) => {
  const { token, name, limit = 10000 } = options;
  const query = useQuery(
    ["nomenclature", token, name],
    async () => {
      const response = apiRequests.nomenclature.getItems({ token, name, limit })
      return response.data.result?.sort((a, b) => a.id - b.id);
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return query;
};

export const useFetchCreateNomenclature = (token, onSuccess) => {
  const { mutate, isLoading, isError, error, isSuccess, data, mutateAsync } =
    useMutation({
      mutationFn: async (options) => {
        return await apiRequests.nomenclature.post(options);
      },
    });
  return [mutate, { isLoading, isError, error, isSuccess, data, mutateAsync }];
};

export const useFetchEditNomenclature = (token) => {
  const { mutate, isLoading, isError } = useMutation(
    async (options) =>
      await apiRequests.nomenclature.patch(options)
  );
  return [mutate, { isLoading, isError }];
};

export const useFetchDeleteNomenclature = (token) => {
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (ids) =>
      await apiRequests.nomenclature.delete({ data: ids })
  );
  return [mutate, { isLoading, isError, isSuccess }];
};

export const useFetchAllNomenclature = ({ token }) =>
  useFetchAllData({ token, key: "nomenclature", path: "nomenclature/" });

