import { useQuery } from "react-query";
import { apiRequests } from "src/shared/api/api";
import { useFetchAllData } from "src/shared/lib/hooks/context";

export const useFetchGetManufactures = (options) => {
  const { token, name } = options;
  const query = useQuery(
    ["manufacturers", token, name],
    async () => {
      const response = apiRequests.manufacturers.getItems({ token, name });
      return response.data.result;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return query;
};

export const useFetchAllManufacturers = ({ token }) =>
  useFetchAllData({ token, key: "manufacturers", path: "manufacturers/" });
