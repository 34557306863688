import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import * as Sentry from "@sentry/react";
dayjs.locale("ru");

Sentry.init({
  dsn: "https://86b45a2d42bd46a582ff4cdb6f3fe117@sentry.tablecrm.com/2",
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

document.addEventListener("keyup", (e) => {
  if (e.ctrlKey && e.altKey && e.key === "d") {
    import("mimic");
  }
  if (e.ctrlKey && e.altKey && e.key === "f") {
    const search = new URLSearchParams(window.location.search);
    search.set("hideLayout", true);
    window.open(`${window.location.pathname}?${search}`);
  }
});
