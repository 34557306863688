import {CreateDocsSales} from "../index";
import {message} from "antd";
import {useNavigate} from "react-router-dom";

const CreateDocsSalesWrapper = (props) => {
    const [messageApi, contextHandler] = message.useMessage()
    const navigate = useNavigate()
    return (
        <>
            <CreateDocsSales navigate={navigate} messageApi={messageApi} {...props} />
            {contextHandler}
        </>
    )
}

export default CreateDocsSalesWrapper