import React, { lazy, Suspense, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
const AddPriceModal = lazy(() =>
  import("src/features/Modal").then((modal) => ({
    default: modal.AddPrice,
  }))
);

export default function AddPriceButton() {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <Button
        style={{ marginBottom: 15 }}
        icon={<PlusOutlined />}
        type="primary"
        onClick={() => setOpen(true)}
      >
        Добавить цену на товар
      </Button>
      {isOpen ? (
        <Suspense fallback={<div></div>}>
          <AddPriceModal isOpen={isOpen} setOpen={setOpen} />
        </Suspense>
      ) : null}
    </>
  );
}
