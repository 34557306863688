import React, {lazy, Suspense, useMemo, useState} from "react";
import {Table, Collapse, Select, DatePicker, Space, Form, Tabs, Spin} from "antd";
import { LoyalityReportExpandedRow } from "./LoyalityReportExpandedRow";
import { COL_ANALYTICS_CARDS } from "src/enitities/Table/model/constants";
import {FundOutlined, TableOutlined} from "@ant-design/icons";


const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const LoyalityGraphics = lazy(() => import('./LoyalityGraphics'))

export default function LoyalityReport({ loading, dataSource, token, handleChanges }) {
  const [userData, setUserData] = useState([]);

  useMemo(() => {
    if (dataSource) {
      if (dataSource.length !== 0) {
        const userSelect = [];
        for (let item of dataSource) {
          userSelect.push({ value: item.user_id, label: item.first_name });
        }
        setUserData(userSelect);
        return userSelect;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleChanges]);

  const expandedRowRender = (record) => {
    return (
      <LoyalityReportExpandedRow record={record} onChange={handleChanges} />
    );
  };

  const handleSearchByUser = (inputValue) => {
    const filters = {};
    filters.user_id = inputValue;

    if (!inputValue) {
      handleChanges(null, null, null, { action: "filter" });
    } else {
      handleChanges(null, filters, null, { action: "filter" });
    }

  };

  const handleSearchByDate = (inputValue) => {
    const filters = {};
    filters.date = inputValue;
    handleChanges(null, filters, null, { action: "filter" });
  };

  const handleSubmit = async (val) => {
    console.log(val)
    // await axiosInstance.get(`atalytics_card?token=${token}?date_from=${}`)
  }

  const tabsItems = [
    {
      key: 1,
      label: `Таблицы`,
      children: (
          <Table
              loading={loading}
              columns={COL_ANALYTICS_CARDS}
              expandable={{ expandedRowRender }}
              rowKey={(record) => record.user_id}
              dataSource={dataSource}
              onChange={handleChanges}
              rowClassName={() => "editable-row"}
              style={{ width: "100%" }}
          />
      ),
      icon: <TableOutlined />,
    },
    {
      key: 2,
      label: `Графики`,
      children: <Suspense fallback={<Spin />}><LoyalityGraphics dataSource={dataSource} /></Suspense> ,
      icon: <FundOutlined />,
    }
  ]

  return (
    <>
      <Collapse ghost={true} style={{ marginBottom: "15px" }}>
        <Panel header="Фильтр" key="1">
          <Form onFinish={handleSubmit}>
            <Form.Item name={'user_id'}>
              <Select
                  allowClear={true}
                  showSearch={true}
                  options={userData}
                  filterOption={(input, options) =>
                      (options?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                  }
                  onSelect={handleSearchByUser}
                  onClear={handleSearchByUser}
                  style={{ width: "200px" }}
              />
            </Form.Item>
            <Form.Item name={'date'}>
              <RangePicker
                  onChange={(input) => handleSearchByDate(input)}
                  onClear={(input) => console.log(input)}
                  style={{ width: "250px" }}
              />
            </Form.Item>
          </Form>
          <Space>


          </Space>
        </Panel>
      </Collapse>


      <Tabs
          defaultActiveKey={1}
          items={tabsItems}
      />

    </>
  );
}
