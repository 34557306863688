import React, { useContext, useEffect, useState } from "react";
import { Form } from "antd";
import { ModalForm } from "src/enitities/Modal";
import { CategoryForm } from "src/enitities/Form";
import { CategoriesContext } from "src/shared/lib/hooks/context/getCategoriesContext";
import { API } from "../../Table";

export default function AddCategory({ isOpen, setOpen }) {
  const [changeData, setChangeData] = useState(false);
  const { token, data, pathname } = useContext(CategoriesContext);
  const [parent, setParent] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const parent = [];
    for (let item of data) {
      parent.push({ value: item.id, label: item.name });
    }
    setParent(parent);
  }, [data]);

  return (
    <ModalForm
      title={"Добавить категорию"}
      width={500}
      isOpen={isOpen}
      setOpen={setOpen}
      formContext={form}
      handleSubmit={API.crud.create(token, pathname)}
      changeData={changeData}
      setChangeData={setChangeData}
    >
      <CategoryForm
        formContext={form}
        parent={parent}
        setChangeData={setChangeData}
      />
    </ModalForm>
  );
}
