import { useQuery, useMutation, useQueryClient } from "react-query";
import { apiRequests } from "src/shared/api/api";

export const useFetchGetPurchases = (options) => {
  const { token, pageSize, current } = options;
  const query = useQuery(
    ["purchases", current, pageSize, token],
    async () => {
      const response = await apiRequests.docs.purchases({ token, limit: pageSize, offset: (current-1) * +pageSize || undefined });
      return response.data.result;
    }
  );
  return query;
};

export const useFetchGetPurchaseById = (options) => {
  const { token, id } = options;
  const query = useQuery(
    ["purchase", id, token],
    async () => {
      // const params= { token, limit: pageSize, offset: (current-1) * +pageSize || undefined }
      const response = await apiRequests.docs.purchasesItem(id);
      return response.data.result;
    },
    {
      enabled: id !== null&&id !== undefined,
    }
  );
  return query;
};

export const useFetchCreatePurchase = (token, onSuccess) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError,error } = useMutation(
    async (options) =>
      await apiRequests.docs.postPurchases([options]),
    {
      onSuccess: () => {
        if (typeof onSuccess === "function") onSuccess();
        queryClient.invalidateQueries("purchases");
      },
    }
  );
  return [mutate, { isLoading, isError, error }];
};

export const useFetchEditPurchase = (token, onSuccess) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError } = useMutation(
    async (options) =>
      await apiRequests.docs.patchPurchase(
        options?.id,
        [options]
        
      ),
    {
      onSuccess: () => {
        if (typeof onSuccess === "function") onSuccess();
        queryClient.invalidateQueries("purchases");
        queryClient.invalidateQueries("purchase");
      },
    }
  );
  return [mutate, { isLoading, isError }];
};

export const useFetchDeletePurchase = (token) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError } = useMutation(
    async (id) =>
      await apiRequests.docs.deletePurchase(
        id,
        {
          data: [id],
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("purchases");
        queryClient.invalidateQueries("purchase");
      },
    }
  );
  return [mutate, { isLoading, isError }];
};