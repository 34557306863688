import { useQuery } from "react-query";
import { useFetchAllData } from "src/shared/lib/hooks/context";
import { apiRequests } from "src/shared/api/api";

export const useFetchGetContracts = (options) => {
  const { token, name, } = options;
  const query = useQuery(
    ["contracts", token, name],
    async () => {
      const response = await apiRequests.contracts.getItems({ token, name });
      return response.data.result;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return query;
};

export const useFetchAllContracts = ({ token }) =>
  useFetchAllData({ token, key: "contracts", path: "contracts/" });

