import React, { useMemo, useRef, useState } from "react";
import debounce from "lodash/debounce";

import { Select, Spin } from "antd";

const DebounceSelect = ({ fetchOptions, debounceTimeout = 800, ...props }) => {
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    fetchOptions().then((res) => {
      setOptions(res);
    });

    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) return;
        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
};

export { DebounceSelect };
