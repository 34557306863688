import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ContractsContext } from "src/shared";
import { AddContractsButton } from "src/widgets/Button";
import { TableContracts } from "src/features/Table";

export default function TableContractsPage({
  token,
  websocket,
  initialData,
  organizationsData,
  conteragentsData,
}) {
  const { pathname } = useLocation();

  const [data, setData] = useState(initialData);

  useEffect(() => {
    if (initialData) {
      setData(initialData);
    }
  }, [initialData]);

  return (
    <ContractsContext.Provider
      value={{
        token,
        websocket,
        data,
        pathname,
        organizationsData,
        conteragentsData,
      }}
    >
      <AddContractsButton />
      <TableContracts />
    </ContractsContext.Provider>
  );
}
