import { useQuery } from "react-query";
import { useFetchAllData } from "src/shared/lib/hooks/context";
import { apiRequests } from "src/shared/api/api";

export const useFetchGetOrganization = (options) => {
  const { token, name, } = options;
  const query = useQuery(
    ["organization", token, name],
    async () => {
      const response = await apiRequests.organizations.getItems({ token, name });
      return response.data.result;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return query;
};


export const useFetchAllOrganization = ({ token }) =>
  useFetchAllData({ token, key: "organization", path: "organizations/" });
