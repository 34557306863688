import React from "react";
import { Select } from "antd";

const searchResult = async (api, token, query) => {
  return fetch(`${api}warehouses/?token=${token}&name=${query}`)
    .then((response) => response.json())
    .then((body) =>
      body.result.map((user) => ({
        label: `${user.name}`,
        value: user.id,
      }))
    );
};

class WareHousesAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      value: undefined,
    };
  }

  async componentDidMount() {
    const option = await searchResult(this.props.api, this.props.token, "");
    this.setState({
      options: option,
      value: this.props.nodefaultvalue ? null : option[0],
    });
    if (!this.props.nodefaultvalue) this.props.onSelect(option[0]?.value);
  }

  handleSelect = (value) => {
    this.setState({ value: value });
  };

  filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  render() {
    const { options, value } = this.state;

    return (
      <Select
        {...this.props}
        showSearch
        options={options}
        filterOption={this.filterOption}
        onChange={(value) => this.handleSelect(value)}
        value={value}
      ></Select>
    );
  }
}

export default WareHousesAutocomplete;
