import {HotColumn, HotTable} from "@handsontable/react";
import {useEffect, useState} from "react";
import {apiRequests} from "../../shared/api/api";
import {useVirtualization} from "../../features/Table/ui/TablePricesHandsontable/hooks";


const TableContragentsHandsontable = () => {
    const [data, setData] = useState([])
    const { rowRenderer } = useVirtualization()
    const requestContragents = async () => {
        await apiRequests.contragents.getItems()
            .then((res) => {
                setData(res.data.result)
            })
    }

    const changeHandler = async (changes, source) => {

        if (!changes) {
            return false
        }

        let newData = [...data]

        if (source === 'edit') {
            changes?.forEach(([row, prop, oldValue, newValue]) => {

                newData[row][prop] = newValue;
                newData[row]["updated"] = oldValue !== newValue;
            });
        }

        const editContragent = newData.filter(i => i.updated)

        await apiRequests.contragents.put(editContragent[0].id, editContragent[0])
            .then((res) => {

            })

        console.log(editContragent)
    }

    useEffect(() => {
        requestContragents()
    }, []);


    return (
        <HotTable
            licenseKey="non-commercial-and-evaluation"
            autoRowSize={true}
            autoColumnSize={true}
            rowHeaders={true}
            data={data}
            contextMenu
            rowRenderer={rowRenderer}
            onScrollVertically={() => console.log('abra')}
            afterAddChild={(val) => console.log(val)}
            afterChange={changeHandler}
            stretchH="all"
            stretchV="all"
            viewportRowRenderingOffset={20}
        >
            <HotColumn data="name" title="Имя" />
            <HotColumn data="phone" title="Номер" />
            <HotColumn data="birth_date" title="Дата рождения" />
            <HotColumn data="initial_balance_card" title="Начальный баланс карты" />
            <HotColumn data="initial_balance_card" title="Текущий баланс карты" />
            <HotColumn data="initial_balance_card" title="Статус карты" />

        </HotTable>
    )
}

export default TableContragentsHandsontable