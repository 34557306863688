import React, { useMemo, useContext } from "react";
import { Table, Button, Popconfirm, Tooltip } from "antd";
import { NomenclatureContext } from "src/shared/lib/hooks/context/getNomenclatureContext";
import { DeleteOutlined } from "@ant-design/icons";
import { EditableCell, EditableRow } from "src/shared";
import { BarcodeModal, EditNomenclatureModal } from "src/features/Modal/";
import { COL_NOMENCLATURE } from "../model/constants";
import { setColumnCellProps } from "../lib/setCollumnCellProps";

export default function Nomenclature({
  handleRemove,
  total,
  handleSave,
  handleSaveImage,
  handleDeleteImage,
  queryOffsetData,
  dataSource,
  search,
  page,
  pageSize,
}) {
  const { token } = useContext(NomenclatureContext);
  const columns = useMemo(
    () =>
      setColumnCellProps(COL_NOMENCLATURE, {
        type: [
          {
            key: "render",
            action: (record) => {
              if (!record) {
                return "Не указано";
              }

              if (record === "product") {
                return "Товар";
              }

              if (record === "service") {
                return "Услуга";
              }

              return record;
            },
          },
        ],
        name: [
          {
            key: "render",
            action: (record) => (
              <Tooltip placement="topLeft" title={record}>
                {record}
              </Tooltip>
            ),
          },
        ],
        description_short: [
          {
            key: "render",
            action: (record) => (
              <Tooltip placement="topLeft" title={record}>
                {record}
              </Tooltip>
            ),
          },
        ],
        description_long: [
          {
            key: "render",
            action: (record) => (
              <Tooltip placement="topLeft" title={record}>
                {record}
              </Tooltip>
            ),
          },
        ],
        action: [
          {
            key: "render",
            action: (_, record) => (
              <>
                <BarcodeModal record={record} token={token} />
                <EditNomenclatureModal
                  record={record}
                  handleSave={handleSave}
                  handleSaveImage={handleSaveImage}
                  handleDeleteImage={handleDeleteImage}
                />
                <Popconfirm
                  title={"Подтвердите удаление"}
                  onConfirm={() => handleRemove(record.id, dataSource)}
                >
                  <Button icon={<DeleteOutlined />} />
                </Popconfirm>
              </>
            ),
          },
        ],
      }),
    [
      dataSource,
      handleDeleteImage,
      handleRemove,
      handleSave,
      handleSaveImage,
      token,
    ]
  );

  return (
    <>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={dataSource}
        components={{
          body: {
            cell: EditableCell,
            row: EditableRow,
          },
        }}
        pagination={{
          current: page,
          pageSize: pageSize,
          total: total,
          showSizeChanger: true,
          onShowSizeChange: async (_, size) =>
            await queryOffsetData(page, size, search),
          onChange: async (page, pageSize) =>
            await queryOffsetData(page, pageSize, search),
        }}
        bordered
        rowClassName={() => "editable-row"}
        style={{ width: "100%" }}
      />
    </>
  );
}
