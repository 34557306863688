//фильтр на странице "Аналитика"
import React, { useState, useRef } from "react";
import { DebounceSelectFil } from "src/shared";
// import { SearchOutlined } from "@ant-design/icons";
import { Button, Collapse, DatePicker, Form } from "antd";

// const { Option } = Select;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const SalesReportFilter = ({ query, onChange }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const formRef = useRef();

  const [state] = useState({
    name: "",
    tags: "",
    datefrom: null,
    dateto: null,
    project: "",
    paybox: "",
    user: "",
  });

  const fetchPaybox = async (name = "") => {
    if (name) {
      return fetch(`https://${process.env.REACT_APP_APP_URL}/api/v1/payboxes/?token=${query.token}&name=${name}`)
        .then((response) => response.json())
        .then((body) => {
          return body;
        })
        .then((body) => {
          if (body.result) {
            return body.result.map((payment) => ({
              label: payment.name,
              value: payment.id,
            }));
          }
        })
        .then((body) => {
          return body;
        });
    } else {
      if (openFilter) {
        return fetch(`https://${process.env.REACT_APP_APP_URL}/api/v1/payboxes/?token=${query.token}`)
          .then((response) => response.json())
          .then((body) => {
            return body;
          })
          .then((body) => {
            if (body.result) {
              return body.result.map((payment) => ({
                label: payment.name,
                value: payment.id,
              }));
            }
          })
          .then((body) => {
            return body;
          });
      }
    }
  };

  const fetchUsers = async (name) => {
    if (name) {
      return fetch(`https://${process.env.REACT_APP_APP_URL}/api/v1/users/list/?token=${query.token}&name=${name}`)
        .then((response) => response.json())
        .then((body) => {
          return body;
        })
        .then((body) => {
          if (body.result) {
            return body.result.map((user) => ({
              label: `${user.first_name} ${user.last_name}`,
              value: user.id,
            }));
          }
        })
        .then((body) => {
          return body;
        });
    } else {
      if (openFilter) {
        return fetch(`https://${process.env.REACT_APP_APP_URL}/api/v1/users/list/?token=${query.token}`)
          .then((response) => response.json())
          .then((body) => {
            return body;
          })
          .then((body) => {
            if (body.result) {
              return body.result.map((user) => ({
                label: `${user.first_name} ${user.last_name}`,
                value: user.id,
              }));
            }
          })
          .then((body) => {
            return body;
          });
      }
    }
  };

  const onFinish = (values) => {
    onChange(values);
    // Object.keys(values).forEach(
    //   (key) => (values[key] === undefined || values[key] === null || values[key].length === 0) && delete values[key]
    // );
    // Object.keys(values).forEach((key) => {
    //   if (key === "name" || key === "project" || key === "paybox" || key === "contragent") {
    //     values[key] = values[key]["label"];
    //   }
    // });
    // if ("dates" in values) {
    //   values["datefrom"] = values["dates"][0].format("DD-MM-YYYY");
    //   values["dateto"] = values["dates"][1].format("DD-MM-YYYY");
    //   delete values["dates"];
    // }

    // if ("tags" in values) {
    //   values["tags"] = values.tags.map((item) => item.label).join(",");
    // }

    // if (values.payment_type === "all") {
    //   delete values["payment_type"];
    // }

    // if (openFilter) {
    //   onChange(1, values);
    // }
  };

  return (
    <Collapse onChange={() => setOpenFilter(!openFilter)} ghost style={{ marginBottom: 15, marginTop: -5 }}>
      <Panel header="Фильтры отчета по продажам" key="1">
        <Form onFinish={onFinish} ref={formRef} layout={"inline"}>
          <Form.Item name={"payboxes"}>
            <DebounceSelectFil
              fetchOptions={fetchPaybox}
              mode="multiple"
              placeholder="Выберите счёт"
              style={{ width: 350 }}
            />
          </Form.Item>
          <Form.Item name={"users"}>
            <DebounceSelectFil fetchOptions={fetchUsers} placeholder="Пользователь" style={{ width: 200 }} />
          </Form.Item>
          <Form.Item name="dates">
            <RangePicker
              placeholder={["Начальная дата", "Конечная дата"]}
              allowEmpty={[false, false]}
              initialValue={[state.datefrom, state.dateto]}
              format={"DD-MM-YYYY"}
            />
          </Form.Item>
          <Form.Item name={"search"}>
            <Button htmlType="submit" type="primary">
              Сформировать
            </Button>
          </Form.Item>
        </Form>
      </Panel>
    </Collapse>
  );
};

export default SalesReportFilter;
