import React, { useState, useEffect } from "react";
import { PricesContext } from "src/shared";
import { useLocation } from "react-router-dom";
import { TablePrices } from "src/features/Table";
import { AddPriceButton } from "src/widgets/Button";
import FilterPrices from "../../../shared/ui/Tables/FilterPrices";

export default function TablePricesPage({
  websocket,
  token,
  initialData,
  priceTypesData,
  manufacturersData,
  nomenclatureData,
  categoriesData,
  unitsData,
  params,
  setPricesData,
  countData,
}) {
  const [data, setData] = useState(initialData);

  useEffect(() => {
    if (initialData) {
      setData(initialData);
    }
  }, [initialData]);
  const { pathname } = useLocation();
  return (
    <PricesContext.Provider
      value={{
        token,
        params,
        pathname,
        websocket,
        data,
        priceTypesData,
        manufacturersData,
        nomenclatureData,
        categoriesData,
        unitsData,
        setPricesData,
        countData,
      }}>
      <div style={{ display: "flex", gridGap: "8px" }}>
        <AddPriceButton />
        <FilterPrices />
      </div>

      <TablePrices />
    </PricesContext.Provider>
  );
}
