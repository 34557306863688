import React, { useRef } from "react";
import { DatePicker, Form, Input, Select } from "antd";
import { ORG_TYPES, TAX_TYPES } from "src/shared/constants/property";

export default function Organization({
  formContext,
  record,
  parent,
  setChangeData,
}) {
  const formItemLayout = { labelCol: { span: 5 }, wrapperCol: { span: 17 } };

  const onValuesChange = (changedValues, allValues) => {
    setChangeData(true);
  };

  const validateMessages = {
    /* eslint-disable no-template-curly-in-string */
    required: "Поле обязательно для ввода!",
  };

  const formRef = useRef();

  return (
    <Form
      {...formItemLayout}
      ref={formRef}
      form={formContext}
      initialValues={record}
      layout={"horizontal"}
      style={{ maxWidth: "100%" }}
      onValuesChange={onValuesChange}
      validateMessages={validateMessages}
    >
      <Form.Item rules={[{ required: true }]} label={"Тип"} name={"type"}>
        <Input />
      </Form.Item>
      <Form.Item label={"Тип организации"} name={"org_type"}>
        <Select options={ORG_TYPES.map(org => ({label: org, value: org}))} allowClear />
      </Form.Item>
      <Form.Item
        rules={[{ required: true }]}
        label={"Короткое имя"}
        name={"short_name"}
      >
        <Input />
      </Form.Item>
      <Form.Item label={"Полное имя"} name={"full_name"}>
        <Input />
      </Form.Item>
      <Form.Item label={"Рабочее имя"} name={"work_name"}>
        <Input />
      </Form.Item>
      <Form.Item label={"Префикс"} name={"prefix"}>
        <Input />
      </Form.Item>
      <Form.Item label={"Дата регистрации"} name={"registration_date"}>
        <DatePicker format={{ format: 'DD.MM.YYYY', type: 'mask' }} />
      </Form.Item>
      <Form.Item label={"OKVED"} name={"okved"}>
        <Input type={"number"} />
      </Form.Item>
      <Form.Item label={"OKVED2"} name={"okved2"}>
        <Input type={"number"} />
      </Form.Item>
      <Form.Item label={"Тип таксы"} name={"tax_type"}>
        <Select options={TAX_TYPES.map(tax => ({label: tax, value: tax}))} allowClear />
      </Form.Item>
      <Form.Item label={"Такса"} name={"tax_percent"}>
        <Input type={"number"} />
      </Form.Item>
      <Form.Item label={"ИНН"} name={"inn"}>
        <Input type={"number"} />
      </Form.Item>
      <Form.Item label={"КПП"} name={"kpp"}>
        <Input type={"number"}/>
      </Form.Item>
      <Form.Item label={"OKPO"} name={"okpo"}>
        <Input type={"number"}/>
      </Form.Item>
    </Form>
  );
}
