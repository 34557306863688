//модальное окно редактировани контрагента на странице "Контрагенты"
import {
  AutoComplete,
  Button,
  Form,
  Input,
  Modal,
  message,
  DatePicker,
  Radio,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import React from "react";
import { apiRequests } from "src/shared/api/api";
import dayjs from "dayjs";

const layout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 20,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 20,
  },
};

const validateMessages = {
  /* eslint-disable no-template-curly-in-string */
  required: "${label} обязательно!",
};

const { TextArea } = Input;

class EditCA extends React.Component {
  formRef = React.createRef();

  state = { visible: false, changeData: false, isRequestLoading: false };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (item) => {
    this.setState({isRequestLoading: true})
    apiRequests.contragents
      .put(this.props.ca.id, {
        ...item,
        birth_date: dayjs(item.birth_date).format("YYYY-MM-DD"),
      })
      .then((response) => {
        message.success("Вы успешно изменили контрагента");
        this.props.updateRow(this.props.ca, response);
      })
      .catch((err) => {
        if (err.response) {
          this.props.updateRow(this.props.ca, err.response);
        } else {
          this.props.updateRow(this.props.ca, {
            status: 500,
            data: err.message,
          });
        }
      })
        .finally(() => this.handleCancel())
    ;

  };

  handleState = () => {
    this.setState({ visible: false, changeData: false, isRequestLoading: false });
  };

  handleCancel = () => {
    this.state.changeData === true
      ? Modal.confirm({
          title: "Подтвердите действие",
          content: "Вы дейтвительно хотите закрыть окно?",
          okText: "Да",
          cancelText: "Нет",
          onOk: () => {
            this.handleState();
          },
        })
      : this.handleState();
  };

  onValuesChange = (changedValues, allValues) => {
    this.setState({ changeData: true });
  };

  render() {
    const { visible } = this.state;
    return (
      <>
        <Button
          style={{ marginRight: 10 }}
          icon={<EditOutlined />}
          onClick={this.showModal}
        ></Button>
        <Modal
          open={visible}
          title="Редактирование контрагента"
          destroyOnClose={true}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Form
            {...layout}
            ref={this.formRef}
            name="control-ref"
            validateMessages={validateMessages}
            onValuesChange={this.onValuesChange}
            style={{pointerEvents: `${this.state.isRequestLoading === true ? 'none': ''}`}}
            onFinish={this.handleOk}
            initialValues={this.props.ca}
          >
            <Form.Item
              name="name"
              label="Имя контрагента"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <AutoComplete
                style={{ width: 250 }}
                // options={this.props.meta.names}
                allowClear={false}
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>

            <Form.Item name="inn" label="ИНН контрагента">
              <AutoComplete
                style={{ width: 250 }}
                // options={this.props.meta.inns}
                allowClear={false}
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>

            <Form.Item name="phone" label="Телефон контрагента">
              <AutoComplete
                style={{ width: 250 }}
                // options={this.props.meta.phones}
                allowClear={false}
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>

            <Form.Item name="description" label="Описание">
              <TextArea rows={3} />
            </Form.Item>

            <Form.Item
              name={"birth_date"}
              label={"Дата рождения"}
              messageVariables={"Поле не может быть пустым"}
            >
              <DatePicker format="DD.MM.YYYY" />
            </Form.Item>

            <Form.Item
              name={"contragent_type"}
              label={"Тип контрагента:"}
              messageVariables={"Поле не может быть пустым"}
            >
              <Radio.Group
                onChange={(obj) =>
                  this.formRef.current.setFieldsValue({
                    contragent_type: obj.target.value,
                  })
                }
              >
                <Radio.Button value={"Поставщик"}>Поставщик</Radio.Button>
                <Radio.Button value={"Покупатель"}>Покупатель</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item {...tailLayout}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{marginRight: '5px'}}
                loading={this.state.isRequestLoading}
              >
                Подтвердить
              </Button>
              <Button htmlType="button" onClick={this.handleCancel}>
                Отмена
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default EditCA;
