import {Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import React, {lazy, Suspense, useState} from "react";

const AddPriceTypeModal = lazy(() =>
    import("src/features/Modal").then((modal) => ({
        default: modal.AddPriceTypeModal,
    }))
);

const AddPriceType = () => {
    const [isOpen, setOpen] = useState(false)
    return (
        <>
            <Button
                type={'primary'}
                icon={<PlusOutlined />}
                onClick={() => setOpen(true)}
            >
                Создать вид цены
            </Button>
            {
                isOpen && (
                    <Suspense fallback={<span></span>}>
                        <AddPriceTypeModal
                            isOpen
                            setOpen={setOpen}
                        />
                    </Suspense>
                )
            }

        </>
    )
}

export default AddPriceType