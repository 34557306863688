import React, { useState, useEffect } from "react";
import { Button, Card, InputNumber, Modal, Space, Spin, message } from "antd";
import {
  PrinterOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { downloadFile } from "src/shared";

const PrintedFormPrices = ({ token, record }) => {
  const [isOpen, setOpen] = useState(false);
  const [docTempalates, setDocTemplates] = useState([]);
  //   const [prices, setPrices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(1);

  const openModal = () => {
    setOpen(true);
  };

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        if (isOpen) {
          const docTemplatesRes = await axios.get(
            `https://${process.env.REACT_APP_APP_URL}/api/v1/doctemplates/?token=${token}&limit=100&offset=0&page=prices&area=listtableprice`
          );
          const docTemplatesData = docTemplatesRes.data.result;
          setDocTemplates(docTemplatesData);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchTemplates();

    // const fetchPriceData = async () => {
    //   try {
    //     if (isOpen) {
    //       const pricesRes = await axios.get(
    //         `https://${process.env.REACT_APP_APP_URL}/api/v1/prices/${token.record.id}/?token=${t}`
    //       );
    //       const pricesData = pricesRes.data;
    //       setPrices(pricesData);

    //       setIsLoading(false);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     if (docTempalates.length !== 0) {
    //       message.error(
    //         "Произошла ошибка при загрузке данных. Проверьте правильность заполнения формы."
    //       );
    //     }
    //   }
    // };
    // const fetchDataAndTemplates = async () => {
    //   setIsLoading(true);
    //   await Promise.all([fetchTemplates(), fetchPriceData()]);
    //   setIsLoading(false);
    // };
    // fetchDataAndTemplates();
  }, [isOpen, token, docTempalates.length]);

  const fetchPriceGenerated = (item) => {
    message.loading({
      content: "Файл генерируется",
      key: "loading",
      duration: 0,
    });
    const requestData = {
      length: count,
      product: {
        name: record.nomenclature_name.replace(/\t/g, ""),
        price: record.price,
        origin: record.manufacturer_name ? record.manufacturer_name : " ",
      },
    };

    axios
      .post(
        `https://${process.env.REACT_APP_APP_URL}/api/v1/docgenerated/?token=${token}&template_id=${item.id}&entity=${item.name}&type_doc=pdf&entity_id=${record.id}&tags=${item.tags}`,
        requestData
      )
      .then((response) => {
        const doc_link = response.data.doc_link.slice(13);
        navigator.clipboard.writeText(
          `https://${process.env.REACT_APP_APP_URL}/api/v1/docgenerated/file/${doc_link}/?type_doc=pdf`
        );
        axios
          .get(
            `https://${process.env.REACT_APP_APP_URL}/api/v1/docgenerated/file/${doc_link}/?type_doc=pdf`,
            { responseType: "blob" }
          )
          .then(async (response) => {
            const blobData = await response.data;
            downloadFile(blobData, doc_link, response.headers["content-type"]);
          })
          .catch((error) => {
            message.error({
              content: "Ошибка при открытии файла",
              key: "loading",
            });
          });
      })
      .catch((error) => {
        console.error("Ошибка при выполнении запроса:", error);
        message.error({
          content: "Не удалось сгенерировать файл",
          key: "loading",
        });
      });
  };

  return (
    <>
      <Button
        icon={<PrinterOutlined />}
        onClick={openModal}
        style={{ marginRight: 5 }}
      />
      <Modal
        title={"Формы для печати"}
        open={isOpen}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        {isLoading ? (
          <Space
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: 20,
            }}
          >
            <Spin />
          </Space>
        ) : docTempalates.length !== 0 ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ marginRight: 5 }}>Количество: </p>
              <InputNumber
                min={1}
                style={{ marginRight: 5 }}
                value={count}
                onChange={(val) => setCount(val)}
              />
              <Button
                style={{ marginRight: 5 }}
                icon={<PlusOutlined />}
                onClick={() => {
                  if (count >= 0) setCount(count + 1);
                }}
              />
              <Button
                icon={<MinusOutlined />}
                onClick={() => {
                  if (count > 0) setCount(count - 1);
                }}
              />
            </div>

            {docTempalates.map((item) => (
              <Card
                key={item.id}
                styles={{
                  body: {
                    padding: 16,
                    width: "100%",
                  },
                }}
                style={{ marginBottom: 5 }}
                hoverable
                onClick={() => fetchPriceGenerated(item)}
              >
                {item.description ? item.description : item.name}
              </Card>
            ))}
          </>
        ) : (
          <div style={{ textAlign: "center", padding: 20 }}>
            Запросить настройку шаблонов вы можете в чате партнеров tablecrm.com
            в телеграм по{" "}
            <a
              href="https://t.me/productpartner"
              style={{ textDecoration: "none" }}
            >
              ссылке
            </a>{" "}
            или у вашего интегратора.
          </div>
        )}
      </Modal>
    </>
  );
};

export default PrintedFormPrices;
