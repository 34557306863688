// модальное окно редактирования платежа на старнице "Платежи"
import {
  AutoComplete,
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Switch,
  Alert,
  Select,
  Spin, Space,
} from "antd";

import {
  EditOutlined,
} from "@ant-design/icons";

import React from "react";
import {
  ContragentAutocomplete,
  NumericAutoComplete,
} from "src/shared";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { apiRequests } from "src/shared/api/api";

dayjs.extend(utc);

const layout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 20,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 20,
  },
};

const timeLifeOptions = [
  {
    value: 'Минуты',
    label: 'Минуты'
  },
  {
    value: 'Дни',
    label: 'Дни'
  },
  {
    value: 'Недели',
    label: 'Недели'
  },
]

const validateMessages = {
  /* eslint-disable no-template-curly-in-string */
  required: "${label} обязательно!",
};

const { TextArea } = Input;

class EditLoyalityCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isChangeLifetime: false,
      lifetime: this.props.loyalityCard.lifetime,
      lifetimeType: 'Минуты',
      modalOpened: false,
      changeData: false,
      isLoaded: false,
      current_contragent: null,
      findedContr: null,
      organisations: [],
    };

    this.api = `https://${process.env.REACT_APP_APP_URL}/api/v1/`;
  }

  formRef = React.createRef();

  showModal = () => {
    const { loyalityCard, organisations } = this.props;


    const orgs = organisations
      .map((item) => {
        return { label: item.short_name, value: item.id };
      })
      .filter((item) => item.label);

    if (loyalityCard.contragent) {
      apiRequests.contragents.getItem(loyalityCard.contragent_id)
        .then((response) => {
          this.setState({
            modalOpened: true,
            isContrLoaded: true,
            current_contragent: response.data,
            organisations: orgs,
          });
        });
    } else {
      this.setState({
        modalOpened: true,
        isContrLoaded: true,
        current_contragent: null,
      });
    }
  };

  closeModal = () => {
    this.setState({ modalOpened: false, isChangeLifetime: false })
  }

  handleState = () => {
    this.setState({ modalOpened: false, changeData: false });
  };

  handleCancel = () => {
    this.state.changeData === true
      ? Modal.confirm({
        title: "Подтвердите действие",
        content: "Вы дейтвительно хотите закрыть окно?",
        okText: "Да",
        cancelText: "Нет",
        onOk: () => {
          this.handleState();
        },
      })
      : this.handleState();
  };

  onValuesChange = (changedValues, allValues) => {
    this.setState({ changeData: true });
  };

  randomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  setInitialValues = () => {
    const { current_contragent } = this.state;

    let startValues = Object.assign({}, this.props.loyalityCard);

    startValues.start_period = dayjs.unix(startValues.start_period);
    startValues.end_period = dayjs.unix(startValues.end_period);

    if (current_contragent) {
      startValues.contragent_name = current_contragent.name;
      startValues.contragent_phone = current_contragent.phone;
      startValues.contragent_inn = current_contragent.inn;
      startValues.contragent_desc = current_contragent.description;
    }

    return startValues;
  };

  onFinish = async (values) => {
    const {
      current_contragent,
    } = this.state;
    let requestBody = {};

    values.start_period = values.start_period.unix();
    values.end_period = values.end_period.unix();

    let body = {
      name: values.contragent_name,
      inn: values.contragent_inn,
      phone: values.contragent_phone,
      description: values.contragent_desc,
    };

    for (let i in body) {
      if (!body[i]) {
        delete body[i];
      }
    }
    // Если выбран (апдейт)
    if (
      (values.contragent_name !== current_contragent.name ||
        values.contragent_phone !== current_contragent.phone ||
        values.contragent_inn !== current_contragent.inn ||
        values.contragent_desc !== current_contragent.description)
    ) {
      requestBody.contragent = current_contragent.id;
      await apiRequests.contragents.put(current_contragent.id, body);
    }

    // Если ничего не поменялось
    if (
      values.contragent_name === current_contragent.name &&
      values.contragent_phone === current_contragent.phone &&
      values.contragent_inn === current_contragent.inn &&
      values.contragent_desc === current_contragent.description
    ) {
      requestBody.contragent = current_contragent.id;
    }

    delete values.contragent_name;
    delete values.contragent_phone;
    delete values.contragent_inn;
    delete values.contragent_desc;

    for (const [key, value] of Object.entries(values)) {
      if (value !== this.props.loyalityCard[key]) {
        if (key === 'lifetime') {
          let lifetimeNumber = value
          if (this.state.lifetimeType === 'Минуты') {
            lifetimeNumber = value * 60
          }
          if (this.state.lifetimeType === 'Дни') {
            lifetimeNumber = value * 86400
          }
          if (this.state.lifetimeType === 'Недели') {
            lifetimeNumber = 60 * 60 * 24 * ( 7 * value)
          }
          requestBody[key] = lifetimeNumber;

        } else {
          requestBody[key] = value;
        }
      }
    }

    await apiRequests.loyalityCards.patch(this.props.loyalityCard.id, requestBody)
        .then((i) => this.setState({lifetime: i.data.lifetime}))

    this.closeModal();
  };

  findContragent = async (id) => {
    return fetch(
      `https://${process.env.REACT_APP_APP_URL}/api/v1/contragents/${id}/?token=${this.props.token}`
    )
      .then((response) => response.json())
      .then((body) => {
        return body;
      });
  };

  onSelectCa = (val) => {
    this.findContragent(val).then((res) => {
      this.setState({
        current_contragent: res,
      });
      this.formRef.current.setFieldsValue({
        contragent_name: res.name,
        contragent_phone: res.phone,
        contragent_inn: res.inn,
        contragent_desc: res.description,
      });
    });
  };

  onChangeCaName = (val) => {
    const { current_contragent } = this.state;
    if (current_contragent) {
      if (val !== current_contragent.name) {
      } else {
        this.formRef.current.setFieldsValue({
          contragent_name: current_contragent.name,
          contragent_phone: current_contragent.phone,
          contragent_inn: current_contragent.inn,
          contragent_desc: current_contragent.description,
        });
      }
    } else {
      this.setState({
        newContrName: val,
      });
    }
  };

  onChangeCaPhone = (val) => {
    const { current_contragent } = this.state;

    if (current_contragent) {
      this.formRef.current.setFieldsValue({
        contragent_phone: val,
      });
    } else {
      this.formRef.current.setFieldsValue({
        contragent_phone: val,
      });
    }
  };


  // ВОЗМОЖНО НУЖЕН РЕФАКТОР ???
  onSelectTypeLifetime = (val) => {
    if (this.state.isChangeLifetime === true) {
      switch (val) {
        case 'Минуты':
          this.formRef.current.setFieldValue('lifetime', this.state.lifetime)
          break;
        case 'Дни':
          this.formRef.current.setFieldValue('lifetime', this.state.lifetime)
          break;
        case 'Недели':
          this.formRef.current.setFieldValue('lifetime', this.state.lifetime)
          break;
        default:
          break;
      }
    } else if (this.state.isChangeLifetime === false) {
      switch (val) {
        case 'Минуты':
          this.setState({lifetime: this.props.loyalityCard.lifetime / 60})
          break;
        case 'Дни':
          this.setState({lifetime: this.props.loyalityCard.lifetime / 86400})
          break;
        case 'Недели':
          this.setState({lifetime: this.props.loyalityCard.lifetime / (86400 * 7)})
          break;
        default:
          break;
      }
    }

  }

  onChangeCaInn = (val) => {
    const { current_contragent } = this.state;

    if (current_contragent) {
      this.formRef.current.setFieldsValue({
        contragent_inn: val,
      });
    };
  }

  render() {
    const {
      modalOpened,
      isContrLoaded,
      current_contragent,
      innsMeta,
      phonesMeta,
    } = this.state;


    return (
      <>
        <Button
          style={{ marginRight: 10 }}
          icon={<EditOutlined />}
          onClick={() => this.showModal()}
        />
        <Modal
            afterOpenChange={() => this.onSelectTypeLifetime('Минуты')}
          open={modalOpened}
          width={700}
          title={"Редактирование карты лояльности"}
          destroyOnClose={true}
          onCancel={this.handleCancel}
          footer={null}
        >
          {isContrLoaded ? (
            <Form
              {...layout}
              ref={this.formRef}
              style={{ marginTop: 20 }}
              validateMessages={validateMessages}
              onValuesChange={this.onValuesChange}
              onFinish={this.onFinish}
              initialValues={this.setInitialValues()}
            >
              <Form.Item name="card_number" label="Номер карты">
                <AutoComplete
                  style={{ width: 300 }}
                  placeholder={"Введите номер карты"}
                  // options={namesMeta}
                  allowClear={true}
                // onSearch={this.fetchNames}
                />
              </Form.Item>

              <Form.Item name="balance" label="Баланс">
                {this.props.loyalityCard.balance}
              </Form.Item>

              <Form.Item name="income" label="Сумма входящих">
                {this.props.loyalityCard.income}
              </Form.Item>

              <Form.Item name="outcome" label="Сумма исходящих">
                {this.props.loyalityCard.outcome}
              </Form.Item>

              <Form.Item name="cashback_percent" label="Процент кешбека">
                <InputNumber
                  style={{ width: 300 }}
                  placeholder={"Введите процент кэшбека"}
                  min="0"
                  step="1"
                  stringMode
                />
              </Form.Item>


              <Form.Item
                label={"Минимальная сумма чека"}
                name="minimal_checque_amount"
              >
                <InputNumber
                  style={{ width: 300 }}
                  placeholder={"Введите минимальную сумму чека"}
                  min="0"
                  step="1"
                  stringMode
                />
              </Form.Item>

              <Form.Item
                label={"Максимальный процент начисления"}
                name="max_percentage"
              >
                <InputNumber
                  style={{ width: 300 }}
                  placeholder={"Введите максимальный процент начисления"}
                  min="0"
                  step="1"
                  stringMode
                />
              </Form.Item>
              <Form.Item
                label={"Максимальный процент списания"}
                name="max_withdraw_percentage"
              >
                <InputNumber
                  style={{ width: 300 }}
                  placeholder={"Введите максимальный процент списания"}
                  min="0"
                  step="1"
                  stringMode
                />
              </Form.Item>
              <Divider />

              <Form.Item label={"Организация"} name="organization_id">
                <Select
                  style={{ width: 300 }}
                  options={this.state.organisations}
                />
              </Form.Item>

              <Divider />

              <>
                <div>
                  <Alert
                    type="success"
                    message={
                      <div>
                        Выбран <b>{current_contragent.name}</b>
                      </div>
                    }
                    showIcon
                  />
                  <br />
                </div>
              </>

              <Form.Item label="Имя контрагента" name="contragent_name">
                <ContragentAutocomplete
                  api={this.api}
                  token={this.props.token}
                  onChange={this.onChangeCaName}
                  onSelect={this.onSelectCa}
                />
              </Form.Item>

              <Form.Item
                label="Телефон контрагента"
                name="contragent_phone"
              >
                <NumericAutoComplete
                  api={this.api}
                  token={this.props.token}
                  options={phonesMeta}
                  by={"phone"}
                  onChange={this.onChangeCaPhone}
                  onSelect={this.onSelectCa}
                />
              </Form.Item>

              <Form.Item
                label="ИНН контрагента"
                name="contragent_inn"
                rules={[
                  {
                    pattern: "^\\d+$",
                    message: "ИНН не должен содержать символы кроме цифр",
                  },
                ]}
              >
                <NumericAutoComplete
                  api={this.api}
                  token={this.props.token}
                  options={innsMeta}
                  by={"inn"}
                  onChange={this.onChangeCaInn}
                  onSelect={this.onSelectCa}
                />
              </Form.Item>

              <Form.Item label="Примечание:" name="description">
                <TextArea rows={3} style={{ width: 330 }} />
              </Form.Item>

              <Divider />

              <Form.Item
                label="Статус карты:"
                name="status_card"
                valuePropName="checked"
              >
                <Switch
                  onChange={(checked) => this.setState({ p_status: checked })}
                />
              </Form.Item>

              <Form.Item
                  label="Срок жизни" name='lifetime' >
                <Space style={{width: '100%'}}>
                  <InputNumber
                      value={this.state.lifetime}
                      onChange={(val) => {
                        this.setState({lifetime: val, isChangeLifetime: true})
                      }}
                  />
                  <Select
                      onChange={(val) => this.setState({lifetimeType: val})}
                      style={{width: '150px'}}
                      options={timeLifeOptions}
                      defaultValue={'Минуты'}
                      onSelect={this.onSelectTypeLifetime}
                  />
                </Space>

              </Form.Item>

              <Form.Item label="Начало действия карты:" name="start_period">
                <DatePicker
                  // presets={rangePresets}
                  showTime
                  allowClear={false}
                  format="DD.MM.YY HH:mm:ss"
                // onChange={onRangeChange}
                />
              </Form.Item>

              <Form.Item label="Конец действия карты:" name="end_period">
                <DatePicker
                  // presets={rangePresets}
                  showTime
                  allowClear={false}
                  format="DD.MM.YY HH:mm:ss"
                // onChange={onRangeChange}
                />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: 5 }}
                >
                  Подтвердить
                </Button>
                <Button htmlType="button" onClick={this.closeModal}>
                  Отмена
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <Spin tip="Пожалуйста, подождите">
              <div
                style={{
                  padding: 50,
                  borderRadius: 4,
                  background: "rgba(0, 0, 0, 0.05)",
                }}
              />
            </Spin>
          )}
        </Modal>
      </>
    );
  }
}

export default EditLoyalityCard;
