import React from "react";
import { Form, Input, Select } from "antd";

export default function Categories({
  formContext,
  record,
  parent,
  setChangeData,
}) {
  const { TextArea } = Input;
  const formItemLayout = { labelCol: { span: 5 }, wrapperCol: { span: 17 } };

  const onValuesChange = (changedValues, allValues) => {
    setChangeData(true);
  };

  const validateMessages = {
    /* eslint-disable no-template-curly-in-string */
    required: "Поле обязательно для ввода!",
  };

  return (
    <Form
      {...formItemLayout}
      form={formContext}
      initialValues={record}
      layout={"horizontal"}
      style={{ maxWidth: 600 }}
      validateMessages={validateMessages}
      onValuesChange={onValuesChange}
    >
      <Form.Item rules={[{ required: true }]} label={"Имя"} name={"name"}>
        <Input />
      </Form.Item>
      <Form.Item label={"Описание"} name={"description"}>
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item label={"Код"} name={"code"}>
        <Input />
      </Form.Item>
      <Form.Item label={"Родитель"} name={"parent"}>
        <Select options={parent} />
      </Form.Item>
    </Form>
  );
}
