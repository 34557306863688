import React, { useEffect, useState } from "react";
import { Form, Button } from "antd";
import {
  CreditCardOutlined,
} from "@ant-design/icons";
import { ModalForm } from "src/enitities/Modal";
import { PayboxSelectForm } from "src/enitities/Form";
import { apiRequests } from "src/shared/api/api";

export default function EditOrganizationPaybox({ record }) {
  const [isOpen, setOpen] = useState(false);
  const [changeData, setChangeData] = useState(false);
  const [form] = Form.useForm();

  const [payboxes, setPayboxes] = useState([]);
  useEffect(() => {
    apiRequests.payboxes.getItems().then(resp => {
      setPayboxes(resp.data.result);
    });
  }, [])

  return (
    <>
      <Button
        icon={<CreditCardOutlined />}
        onClick={() => setOpen(true)}
        style={{ marginRight: "5px" }}
      />
      <ModalForm
        title={"Прикрепить счет"}
        width={500}
        isOpen={isOpen}
        setOpen={setOpen}
        formContext={form}
        handleSubmit={async ([data]) => {
          const currentPaybox = payboxes.find(p => p.organization_id === record.id);
          const newPaybox = payboxes.find(pb => pb.id === data.paybox);
          if (currentPaybox) {
            await apiRequests.payboxes.put({
              ...currentPaybox,
              organization_id: null
            })
          }
          if (newPaybox) {
            apiRequests.payboxes.put({
              ...newPaybox,
              organization_id: record.id
            })
          }
        }}
        changeData={changeData}
        setChangeData={setChangeData}
      >
        <PayboxSelectForm
          formContext={form}
          setChangeData={setChangeData}
          record={record}
          payboxes={payboxes}
        />
      </ModalForm>
    </>
  );
}
