import { AutoComplete, Button, Collapse, DatePicker, Form, Input, Select, Spin } from "antd";
import React, { useContext, useState } from "react";
import { PricesContext } from "../../lib/hooks/context/getPricesContext";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { apiRequests } from "../../api/api";
import { useSearchParams } from "react-router-dom";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";

const FilterPrices = () => {
  const { token, priceTypesData, categoriesData } = useContext(PricesContext);
  const { Panel } = Collapse;
  const { RangePicker } = DatePicker;
  const [optionsType, setOptionsType] = useState([]);
  const [optionsCategories, setOptionsCategories] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setLoading] = useState(false);
  const [form] = useForm();

  const searchHandler = async (values) => {
    for (let key in values) {
      if (key === "date") {
        if (values[key] !== (undefined || null)) {
          values["date_from"] = Math.floor(new Date(values[key][0].$d).getTime() / 1000);
          values["date_to"] = Math.floor(new Date(values[key][1].$d).getTime() / 1000);
          delete values[key];
        }
      }
      if (key === "category_ids") {
        if (values[key]?.length > 0) {
          const category = optionsCategories.find((i) => i.value === values[key]);
          values[key] = category.key;
        }
      }
      if (!values[key]) {
        delete values[key];
      }
    }
    setLoading(true);
    setSearchParams({
      token,
      limit: 10,
      page: 1,
      ...values,
    });
    await apiRequests.prices
      .getItems({ ...values })
      .then((res) => {
        // console.log('search.', res)
      })
      .finally(() => setLoading(false));
  };

  const getData = async () => {
    if (priceTypesData) {
      setOptionsType(
        [...priceTypesData].map((i) => ({
          value: i.id,
          key: i.id,
          label: i.name,
        }))
      );
    }

    setOptionsCategories(
      [...categoriesData].map((i) => ({
        value: i.name,
        label: i.name,
        key: i.id,
      }))
    );
  };

  const visibleHandler = () => {
    if (!isOpen) {
      setOpen(true);
      getData().then(() => {
        searchParams.forEach((value, name) => {
          if (name === "category_ids") {
            // eslint-disable-next-line
            const category = categoriesData.find((i) => i.id == value);
            form.setFieldValue(name, category.name);
          } else {
            !Number(value) ? form.setFieldValue(name, value) : form.setFieldValue(name, Number(value));
          }
        });
      });
    } else {
      setOpen(false);
    }
  };

  const resetFields = () => {
    setSearchParams({
      token,
    });
    form.resetFields();
  };

  return (
    <Collapse onChange={visibleHandler} ghost style={{ margin: "-5px 0" }}>
      <Panel header="Фильтры поиска установки цен" key="1">
        <Form
          layout={"inline"}
          style={{
            width: "100%",
            marginBottom: "15px",
            rowGap: "15px",
            pointerEvents: `${isLoading === true ? "none" : ""}`,
          }}
          form={form}
          onFinish={searchHandler}>
          <Form.Item name={"name"} label="Название">
            <Input placeholder="название" />
          </Form.Item>
          <Form.Item name={"price_type_id"}>
            <Select style={{ width: 150 }} options={optionsType} allowClear></Select>
          </Form.Item>
          <Form.Item name={"category_ids"}>
            <AutoComplete style={{ minWidth: "275px" }} options={optionsCategories} allowClear></AutoComplete>
          </Form.Item>
          <Form.Item
            name="date"
            initialValue={
              searchParams.get("date_from") && searchParams.get("date_to")
                ? [dayjs.unix(searchParams.get("date_from")), dayjs.unix(searchParams.get("date_to"))]
                : null
            }>
            <RangePicker
              style={{ width: 300 }}
              placeholder={["Начальная дата", "Конечная дата"]}
              allowEmpty={[false, false]}
              format={"DD-MM-YYYY"}
            />
          </Form.Item>
          <Button icon={<DeleteOutlined />} danger={true} onClick={resetFields} style={{ marginRight: "6px" }}>
            Очистить
          </Button>
          <Form.Item>
            <Button
              style={isLoading === true ? { display: "flex", alignItems: "center", gridGap: "8px" } : null}
              icon={isLoading === true ? null : <SearchOutlined />}
              htmlType={"submit"}>
              {isLoading === true ? <Spin size={"small"} /> : null}
              Поиск
            </Button>
          </Form.Item>
        </Form>
      </Panel>
    </Collapse>
  );
};

export default FilterPrices;
