import React, { useState, useEffect } from "react";
import { AddLoyalitySetting } from "src/widgets/Button";
import { TableLoyalitySetting } from "src/features/Table";
import { LoyalitySettingContext } from "src/shared";
import { useLocation } from "react-router-dom";

const organizationField = (organizations) => {
  const organizationSelect = [];
  if (organizations) {
    for (let item of organizations) {
      organizationSelect.push({ value: item.id, text: item.short_name });
    }
  }
  return organizationSelect;
};

export default function TableLoyalitySettingPage({
  token,
  params,
  websocket,
  initialData,
  organizationsData,
}) {
  const [data, setData] = useState(initialData);
  useEffect(() => {
    if (initialData) {
      setData(initialData);
    }
  }, [initialData]);

  const [organizations, setOrganizations] = useState(null);
  useEffect(() => {
    if (organizationsData) {
      setOrganizations(organizationField(organizationsData));
    }
  }, [organizationsData]);
  const { pathname } = useLocation();
  return (
    <>
      <LoyalitySettingContext.Provider
        value={{
          token,
          data,
          organizations,
          pathname,
          websocket,
          params,
        }}
      >
        <AddLoyalitySetting />
        <TableLoyalitySetting />
      </LoyalitySettingContext.Provider>
    </>
  );
}
