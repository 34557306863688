import React, { useEffect, useRef, } from "react";
import { Form, Select } from "antd";

export default function PayboxSelect({
  formContext,
  record,
  setChangeData,
  payboxes
}) {
  const formItemLayout = { labelCol: { span: 5 }, wrapperCol: { span: 17 } };
  const onValuesChange = (changedValues, allValues) => {
    setChangeData(true);
  };

  const validateMessages = {
    /* eslint-disable no-template-curly-in-string */
    required: "Поле обязательно для ввода!",
  };

  const formRef = useRef();

  useEffect(() => {
    formRef.current.setFieldValue('paybox', payboxes.find(paybox => paybox.organization_id === record.id)?.id)
  }, [payboxes, record.id])


  return (
    <Form
      {...formItemLayout}
      ref={formRef}
      form={formContext}
      initialValues={{}}
      layout={"horizontal"}
      style={{ maxWidth: "100%" }}
      onValuesChange={onValuesChange}
      validateMessages={validateMessages}
    >
      <Form.Item label={"Счет"} name={"paybox"}>
        <Select options={payboxes.map(paybox => ({ label: paybox.name, value: paybox.id }))} allowClear />
      </Form.Item>
    </Form>
  );
}
