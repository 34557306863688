// модальное окно добавление проекта на странице "Проекты"
import React from "react";
import { Modal, Button, Form, Input, InputNumber, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { apiRequests } from "src/shared/api/api";

const layout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 20,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 12,
    span: 15,
  },
};

const validateMessages = {
  /* eslint-disable no-template-curly-in-string */
  required: "${label} обязательно!",
};

class NewProjModal extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      changeData: false,
      isModalVisible: false,
      init_values: {
        name: "Проект",
        proj_sum: 0,
      },
    };
  }
  render() {
    const showModal = () => {
      this.setState({ isModalVisible: true });
    };

    const handleOk = (data) => {
      this.setState({ isModalVisible: false });
      apiRequests.projects.post(data)
        .then((response) => {
          message.success(<>Вы успешно создали проект</>);
        });
    };

    const handleState = () => {
      this.setState({ isModalVisible: false, changeData: false });
    };

    const handleCancel = () => {
      this.state.changeData === true
        ? Modal.confirm({
            title: "Подтвердите действие",
            content: "Вы дейтвительно хотите закрыть окно?",
            okText: "Да",
            cancelText: "Нет",
            onOk: () => {
              handleState();
            },
          })
        : handleState();
    };

    const onValuesChange = (changedValues, allValues) => {
      this.setState({ changeData: true });
    };

    return (
      <>
        <Button
          style={{ marginBottom: 15 }}
          icon={<PlusOutlined />}
          type="primary"
          onClick={showModal}
        >
          Добавить проект
        </Button>
        <Modal
          destroyOnClose={true}
          footer={null}
          title="Добавить новый проект"
          open={this.state.isModalVisible}
          onCancel={handleCancel}
          onValuesChange={onValuesChange}
        >
          <Form
            {...layout}
            ref={this.formRef}
            name="control-ref"
            validateMessages={validateMessages}
            onFinish={handleOk}
            initialValues={this.state.init_values}
          >
            <Form.Item
              name="name"
              label="Название проекта"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input style={{ width: 250 }}></Input>
            </Form.Item>

            <Form.Item name="proj_sum" label="Бюджет проекта">
              <InputNumber
                step={0.01}
                precision={2}
                min={0}
                style={{ width: 250 }}
              />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: 5 }}
              >
                Подтвердить
              </Button>
              <Button htmlType="button" onClick={handleCancel}>
                Отмена
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default NewProjModal;
