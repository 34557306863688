import {Button, Collapse, Form, Input, message, Spin} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useState} from "react";
import {useSearchParams} from "react-router-dom";
import {apiRequests} from "../../api/api";
import {DeleteOutlined, SearchOutlined} from "@ant-design/icons";


const FilterContragents = ({token, updateData}) => {
    const {Panel} = Collapse
    const [open, setOpen] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [form] = useForm()
    const [isLoading, setLoading] = useState(false)

    const visibleHandler = () => {
        if (!open) {
            setOpen(true)
            searchParams.forEach((value, name) => {
                form.setFieldValue(name, value)
            })
        } else {
            setOpen(false)
        }
    }

    const requestHandler = async (values) => {
        for (let key in values) {
            if (!values[key]) {
                delete values[key]
            }
        }
        setSearchParams({
            token,
            ...values
        })
        setLoading(true)
        await apiRequests.contragents.getItems({...values})
            .then((res) => {
                console.log(res)
                updateData(res.data.result, res.data.count, values)
            })
            .catch(() => {
                message.error('Произошла ошибка')
            })
            .finally(() => setLoading(false))
    }

    const resetFields = () => {
        setSearchParams({
            token
        })
        form.resetFields()
    }

    return (
        <Collapse onChange={visibleHandler} ghost style={{margin: '-5px 0'}}>
            <Panel key={1} header={'Фильтры поиска контрагента'}>
                <Form
                    layout={'inline'}
                    style={{width: '100%', marginBottom: '15px', rowGap: '15px', pointerEvents: `${isLoading === true ? 'none' : ''}`}}
                    form={form}
                    onFinish={requestHandler}
                >
                    <Form.Item name={'name'}>
                        <Input placeholder={'Имя контрагента'} />
                    </Form.Item>
                    <Form.Item name={'phone'}>
                        <Input placeholder={'Номер контрагента'} />
                    </Form.Item>
                    <Form.Item name={'inn'}>
                        <Input placeholder={'ИНН контрагента'} />
                    </Form.Item>
                    <Button
                        onClick={resetFields}
                        icon={<DeleteOutlined />}
                        danger={true}
                        style={{marginRight: '6px'}}
                    >
                        Очистить
                    </Button>
                    <Form.Item>

                        <Button
                            disabled={isLoading}
                            style={ isLoading === true ? {display: 'flex', alignItems: 'center', gridGap: '8px'} : null}
                            htmlType={'submit'}
                            icon={isLoading === true ? null : <SearchOutlined />}
                        >
                            {isLoading === true ? <Spin size={'small'} /> : null}

                            Поиск
                        </Button>
                    </Form.Item>
                </Form>
            </Panel>
        </Collapse>
    )
}

export default FilterContragents