import React, { useState, useContext, useEffect } from "react";
import { Table, Calendar, Button, Popconfirm, Space, message } from "antd";
import { EditPrice, PrintedFormPrices } from "src/features/Modal";
import { DeleteOutlined } from "@ant-design/icons";
import { COL_PRICES } from "../../model/constants";
import { setColumnCellProps } from "../../lib/setCollumnCellProps";
import { PricesContext } from "src/shared/index";
import { EditableRow } from "src/shared/index";
import { EditableCell } from "../lib/EditableCell";
import { apiRequests } from "src/shared/api/api";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(tz);

export default function Prices({
  handleRemove,
  handleSave,
  dataSource,
  dataSourceFilter,
  token,
}) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(12);
  const [countDataResponce, setCountDataResponce] = useState();

  const { categoriesData, priceTypesData, unitsData } =
    useContext(PricesContext);
  const [categories, setCategories] = useState(null);
  const [pirceType, setPriceType] = useState(null);
  const [units, setUnits] = useState(null);
  const [dataFromRequest, setDataFromRequest] = useState();
  const [tableloading, setTableLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (unitsData && unitsData.length !== 0) {
      const unitsSelect = [];
      for (let item of unitsData) {
        unitsSelect.push({ value: item.id, text: item.name });
      }
      setUnits(unitsSelect);
    }
    if (priceTypesData && priceTypesData.length !== 0) {
      const priceTypeSelect = [];
      for (let item of priceTypesData) {
        priceTypeSelect.push({ value: item.id, text: item.name });
      }
      setPriceType(priceTypeSelect);
    }
    if (categoriesData && categoriesData.length !== 0) {
      const categoriesSelect = [];
      for (let item of categoriesData) {
        categoriesSelect.push({ value: item.id, text: item.name });
      }
      setCategories(categoriesSelect);
    }
  }, [categoriesData, priceTypesData, unitsData]);

  const editableCell = (cell) => (record, index) => ({
    record,
    index,
    editable: cell.editable,
    dataIndex: cell.dataIndex,
    handleEdit: handleSave,
  });

  const columns = setColumnCellProps(COL_PRICES, {
    unit_name: [
      {
        key: "filters",
        action: units,
      },
      {
        key: "filterSearch",
        action: true,
      },
    ],
    category_name: [
      {
        key: "filters",
        action: categories,
      },
      {
        key: "filterSearch",
        action: true,
      },
    ],
    price: [
      {
        key: "onCell",
        action: editableCell,
      },
      {
        key: "sorter",
        action: (a, b) => a.price - b.price,
      },
    ],
    price_type: [
      {
        key: "filters",
        action: pirceType,
      },
    ],
    date_to: [
      {
        key: "onCell",
        action: editableCell,
      },
      {
        key: "render",
        action: (_, record) => {
          if (
            !record?.date_to ||
            typeof record?.date_to === Object(null) ||
            record?.date_to === 0
          ) {
            return "не указана";
          } else {
            return dayjs
              .unix(record?.date_to)
              .tz("Europe/Moscow")
              .$d.toLocaleDateString();
          }
        },
      },
      {
        key: "filterDropdown",
        action: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => (
          <div style={{ width: 300 }}>
            <Calendar
              fullscreen={false}
              mode="month"
              headerRender={() => null}
              onChange={(e) => {
                setSelectedKeys([e]);
                confirm(true);
              }}
              allowClear={true}
            />
            <Space
              style={{
                width: "100%",
                padding: "7px 8px",
                borderTop: "1px solid #f0f0f0",
                color: "rgba(0, 0, 0, 0.25)",
              }}
            >
              <Button
                type="link"
                size="small"
                disabled={selectedKeys.length === 0}
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
              >
                Reset
              </Button>
            </Space>
          </div>
        ),
      },
    ],
    date_from: [
      {
        key: "onCell",
        action: editableCell,
      },
      {
        key: "render",
        action: (_, record) => {
          if (
            !record?.date_from ||
            typeof record?.date_from === Object(null) ||
            record?.date_from === 0
          ) {
            return "не указана";
          } else {
            return dayjs
              .unix(record?.date_from)
              .tz("Europe/Moscow")
              .$d.toLocaleDateString();
          }
        },
      },
      {
        key: "filterDropdown",
        action: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => (
          <div style={{ width: 300 }}>
            <Calendar
              fullscreen={false}
              mode="month"
              headerRender={() => null}
              onChange={(e) => {
                setSelectedKeys([e]);
                confirm(true);
              }}
              allowClear={true}
            />
            <Space
              style={{
                width: "100%",
                padding: "7px 8px",
                borderTop: "1px solid #f0f0f0",
                color: "rgba(0, 0, 0, 0.25)",
              }}
            >
              <Button
                type="link"
                size="small"
                disabled={selectedKeys.length === 0}
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: false });
                }}
              >
                Reset
              </Button>
            </Space>
          </div>
        ),
      },
    ],
    action: [
      {
        key: "render",
        action: (_, record) => (
          <>
            <EditPrice record={record} handleSave={handleSave} />
            <PrintedFormPrices token={token} record={record} />
            <Popconfirm
              title={"Подтвердите удаление"}
              onConfirm={() => {
                handleRemove(record.id);
              }}
            >
              <Button icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        ),
      },
    ],
  });

  const getData = async () => {
    setTableLoading(true);
    await apiRequests.prices
      .getItems({
        ...Object.fromEntries(searchParams.entries()),
        limit: perPage,
        page: page,
      })
      .then(({ data }) => {
        console.log(data?.result);
        setDataFromRequest(data?.result);
        setCountDataResponce(data?.count);
      })
      .catch((err) => {
        messageApi.error("Ошибка при получении данных", 3);
        setTableLoading(false);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchParams]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(dataFromRequest);
  }, [dataFromRequest]);

  useEffect(() => {
    getData().finally(() => setPage(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    // setDataFromRequest(dataSourceFilter?.result);
    // console.log(dataSourceFilter?.result);
    setCountDataResponce(dataSourceFilter?.count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSourceFilter]);

  useEffect(() => {
    if (dataSource?.action === "create")
      setDataFromRequest((prev) => [dataSource?.result[0], ...prev]);
    if (dataSource?.action === "delete")
      setDataFromRequest((prev) =>
        prev.filter((item) => item.id !== dataSource.result.id)
      );
    if (dataSource?.action === "edit")
      setDataFromRequest((prev) =>
        prev.map((item) => {
          if (item.id !== dataSource.result.id) {
            return { ...item };
          } else {
            return { ...dataSource.result };
          }
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  const handleChangePagination = async (current, pageSize) => {
    setPage(current);
    setPerPage(pageSize);
  };

  return (
    <>
      {contextHolder}
      <Table
        pagination={{
          total: countDataResponce,
          onChange: handleChangePagination,
          pageSize: perPage,
          current: page,
        }}
        loading={tableloading}
        columns={columns}
        rowKey={(record) => record.id}
        rowClassName={() => "editable-row"}
        components={{
          body: {
            cell: EditableCell,
            row: EditableRow,
          },
        }}
        dataSource={dataFromRequest}
        bordered
        size="small"
        style={{ width: "100%" }}
      />
    </>
  );
}
