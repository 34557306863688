import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import Timeline from "react-vis-timeline-2";
import {
  Form,
  Modal,
  Select,
  Collapse,
  DatePicker,
  Space,
  Button,
  message,
  Tabs,
  // Timeline as AntdTimeline,
} from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { DebounceSelect } from "src/shared";
import { RentForm } from "src/enitities/Form";
import dayjs from "dayjs";
import { apiRequests } from "src/shared/api/api";
import { axiosInstance } from "../../shared/api/axiosInstance";

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const RentalChart = ({ token }) => {
  const [formVisible, setFormVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [newItem, setNewItem] = useState({});
  const [items, setItems] = useState([]);
  const [groups, setGroups] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [formFilter] = Form.useForm();
  const [detailForm] = Form.useForm();
  const [createForm] = Form.useForm();
  const [settingsForm] = Form.useForm();
  const timelineRef = useRef();

  const handleAddNewItem = async () => {
    const newItemWithId = {
      ...newItem,
      id: String(items.length + 1),
      content: `элемент ${items.length + 1}`, //!!! Добавить потом имя контента
    };
    setItems([...items, newItemWithId]);

    const startBooking = createForm.getFieldValue("date_range")[0];
    createForm.setFieldsValue({
      start_booking: Math.floor(new Date(startBooking).getTime() / 1000),
    });

    const endBooking = createForm.getFieldValue("date_range")[1];
    createForm.setFieldsValue({
      end_booking: Math.floor(new Date(endBooking).getTime() / 1000),
    });

    const dateBooking = createForm.getFieldValue("date_app");
    createForm.setFieldsValue({
      date_booking: Math.floor(new Date(dateBooking).getTime() / 1000),
    });

    createForm.resetFields([
      "date_range",
      "tariff",
      "nomenclature",
      "date_app",
    ]);

    const old_sale = createForm.getFieldValue("docs_sales_id");
    const docs_sales = await axiosInstance.get(`/docs_sales/${old_sale.id}/`);
    const booking_driver_id = createForm.getFieldValue("driver");
    apiRequests.booking.post([
      {
        ...createForm.getFieldValue(),
        docs_sales_id: docs_sales?.data.id,
        goods: docs_sales?.data.goods.map((item) => ({
          tariff: "Месяц",
          nomenclature_id: item.nomenclature,
        })),
        booking_driver_id,
      },
    ]);
    createForm.resetFields();
    getData();
    setIsAddModalVisible(false);
  };

  const fetchNomenclature = useCallback(
    async (name) => {
      return fetch(
        `https://${process.env.REACT_APP_APP_URL}/api/v1/nomenclature/?token=${token}&name=${name}`
      )
        .then((response) => response.json())
        .then((body) => {
          if (body.result) {
            return body.result.map((item) => ({
              label: `${item.name}`,
              value: item.id,
            }));
          }
          return [];
        });
    },
    [token]
  );

  const fetchContragents = useCallback(
    async (name) => {
      return fetch(
        `https://${process.env.REACT_APP_APP_URL}/api/v1/contragents/?token=${token}&name=${name}`
      )
        .then((response) => response.json())
        .then((body) => {
          if (body.result) {
            return body.result.map((item) => ({
              label: `${item.name}`,
              value: item.id,
            }));
          }
          return [];
        });
    },
    [token]
  );

  const memoizedFetchContragents = useMemo(() => {
    const cache = new Map();

    return async (name) => {
      if (cache.has(name)) {
        return cache.get(name);
      }
      const result = await fetchContragents(name);
      cache.set(name, result);
      return result;
    };
  }, [fetchContragents]);

  const memoizedFetchNomenclature = useMemo(() => {
    const cache = new Map();

    return async (name) => {
      if (cache.has(name)) {
        return cache.get(name);
      }
      const result = await fetchNomenclature(name);
      cache.set(name, result);
      return result;
    };
  }, [fetchNomenclature]);

  const fetchNomenclatureByCategory = async (
    categoryId,
    token,
    treeLevel,
    data
  ) => {
    const response = data.filter((i) => i.category === categoryId);

    if (response) {
      return response.map((item) => ({
        content: item.name,
        value: item.id,
        id: item.id,
        treeLevel: treeLevel,
      }));
    }
    return [];
  };

  const processTree = async (nodes, token) => {
    let result = [];
    let stack = nodes.map((node) => ({ node, level: 1 }));
    const nomIds = [];
    nodes.forEach((element) => {
      nomIds.push(element.key);
    });
    console.log(nomIds);
    const arrayOfNomenclatures = await axiosInstance.post(
      "/nomenclatures/",
      nomIds
    );
    console.log("", arrayOfNomenclatures.data);
    while (stack.length) {
      const { node, level } = stack.pop();
      const group = {
        content: node.name,
        value: node.key,
        id: node.key,
        treeLevel: level,
      };

      const nomenclature = await fetchNomenclatureByCategory(
        node.key,
        token,
        level + 1,
        arrayOfNomenclatures.data.result
      );
      console.log(nomenclature);

      let nestedGroups = [];
      if (node.children && node.children.length > 0) {
        console.log(node);
        stack.push(
          ...node.children.map((child) => ({ node: child, level: level + 1 }))
        );
        nestedGroups.push(...node.children.map((child) => child.key));
      }
      if (nomenclature.length > 0) {
        nestedGroups.push(...nomenclature.map((item) => item.id));
        result = result.concat(nomenclature);
      }

      if (nestedGroups.length > 0) {
        group.nestedGroups = nestedGroups;
      }

      result.push(group);
    }

    return result;
  };

  const fetchCategory = async (token) => {
    const response = await fetch(
      `https://${process.env.REACT_APP_APP_URL}/api/v1/categories_tree/?token=${token}`
    );
    const body = await response.json();

    if (body.result) {
      const groups = await processTree(body.result, token);
      console.log("FINAL RESULT:", groups);
      return groups;
    }

    return [];
  };

  const getData = async () => {
    messageApi.open({
      type: "loading",
      content: "Загрузка данных..",
      duration: 0,
    });
    try {
      const groupsFetch = await fetchCategory(token);
      setGroups(groupsFetch);

      await apiRequests.booking.getItems().then((res) => {
        const data = res.data;
        const newItems = data.map((item) => ({
          id: item.id,
          content: "Заявка",
          start: new Date(item.start_booking * 1000).toISOString().toString(), // ISO формат
          end: new Date(item.end_booking * 1000).toISOString().toString(), // ISO формат
          group: item.goods[0]?.nomenclature_id,
        }));

        console.log(newItems);

        setItems((prevItems) => [...prevItems, ...newItems]);

        if (timelineRef.current && timelineRef.current.timeline) {
          const timeline = timelineRef.current.timeline;

          timeline.setData({ groups: groupsFetch, items: newItems });
        }
      });
      console.log(groups);
      messageApi.destroy();
      messageApi.open({
        type: "success",
        content: "Данные загружены",
      });
    } catch (error) {
      console.log(error);
      messageApi.destroy();
      messageApi.open({
        type: "error",
        content: "При загрузке данных произошла ошибка",
      });
    }
  };

  useEffect(() => {
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length > 0) {
      const [startDate, endDate] = dates;
      if (startDate && !endDate) {
        // Если только начальная дата
        formFilter.setFieldValue("date_range", [startDate, null]);
      } else if (!startDate && endDate) {
        // Если только конечная дата
        formFilter.setFieldValue("date_range", [null, endDate]);
      } else {
        // Если обе даты
        formFilter.setFieldValue("date_range", [startDate, endDate]);
      }
    } else {
      formFilter.setFieldValue("date_range", []);
    }
  };

  const handleChange = () => {
    const goods = detailForm.getFieldValue("goods").map((id) => ({
      nomenclature_id: id,
      tariff: "Месяц",
    }));
    detailForm.setFieldsValue({ goods });

    const startBooking = detailForm.getFieldValue("date_range")[0];
    detailForm.setFieldsValue({
      start_booking: Math.floor(new Date(startBooking).getTime() / 1000),
    });

    const endBooking = detailForm.getFieldValue("date_range")[1];
    detailForm.setFieldsValue({
      end_booking: Math.floor(new Date(endBooking).getTime() / 1000),
    });

    const dateBooking = detailForm.getFieldValue("date_app");
    detailForm.setFieldsValue({
      date_booking: Math.floor(new Date(dateBooking).getTime() / 1000),
    });

    detailForm.resetFields([
      "date_range",
      "tariff",
      "nomenclature",
      "date_app",
      "goods", //!!! Временное решение
    ]);

    apiRequests.booking.patch([detailForm.getFieldValue()]);
    getData();
    setIsModalVisible(false);
  };

  const tlOptions = {
    orientation: "top",
    start: new Date(),
    end: new Date(1000 * 60 * 60 * 24 + new Date().valueOf()),
    editable: true,
    horizontalScroll: true,
    zoomKey: "ctrlKey",
    zoomMin: 864000000,
    onAdd: (item, callback) => {
      item.content = "Новый заказ";
      console.log(item);
      if (item.end) {
        callback(item);
      } else {
        callback(null);
      }
      // Добавление элемента в таймлайн
      createForm.setFieldsValue({
        date_range: [dayjs(), dayjs().add(1, "month")],
        date_app: dayjs(item.start),
      });
      setNewItem(item);
      setIsAddModalVisible(true);
    },
    onMoving: (item, callback) => {
      if (item.content === "new item") {
        item.content = "Добавление заказа"; // Устанавливаем контент при растягивании
      }
      callback(item);
    },
    onUpdate: (item, callback) => {
      callback(item);
      console.log("Double clicked item:", item);
      setSelectedItem(item);
      apiRequests.booking.getItemById(item.id).then((res) => {
        apiRequests.contragents
          .getItem(res.data.contragent)
          .then((contragentData) => {
            detailForm.setFieldsValue({
              contragent: contragentData.data.id,
            });
          });
        apiRequests.contragents
          .getItem(res.data.contragent_accept)
          .then((contragentData) => {
            detailForm.setFieldsValue({
              contragent_accept: contragentData.data.id,
            });
          });
        const dateApp = new Date(res.data.date_booking * 1000);
        const nomenclature = res.data.goods.map((nom) => ({
          key: nom.id,
          label: nom.name,
          value: nom.id,
        }));
        const goods = res.data.goods.map((nom) => nom.id);
        detailForm.setFieldsValue({
          address: res.data.address,
          comment: res.data.comment,
          date_app: dayjs(dateApp.toISOString()),
          status_doc_sales: res.data.status_doc_sales,
          status_booking: res.data.status_booking,
          tariff: res.data.goods[0].tariff,
          goods: goods,
          nomenclature: nomenclature,
          id: res.data.id,
        });
      });
      detailForm.setFieldsValue({
        // name: item.content,
        date_range: [dayjs(item.start), dayjs(item.end)],
      });
      setIsModalVisible(true);
      message.warning("Редактирование поля Номенклатура в разработке", 3);
    },
  };

  return (
    <div>
      {contextHolder}
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            key: "1",
            label: "Аренда",
            children: (
              <div>
                {" "}
                <Collapse ghost onChange={() => setFormVisible(!formVisible)}>
                  <Panel header="Фильтр поиска заявок" key="1">
                    {formVisible && (
                      <Form form={formFilter} layout={"inline"}>
                        <Space style={{ marginBottom: 10 }}>
                          <Form.Item
                            name="name_nomenclature"
                            style={{ maxWidth: 300 }}
                          >
                            <DebounceSelect
                              showSearch
                              labelInValue
                              allowClear="true"
                              placeholder="Наименование номенклатуры"
                              fetchOptions={memoizedFetchNomenclature}
                              onChange={(data) =>
                                formFilter.setFieldValue(
                                  "name_nomenclature",
                                  data.key
                                )
                              }
                            />
                          </Form.Item>
                          <Form.Item
                            style={{ width: 200 }}
                            name="category_nomenclature"
                          >
                            <Select
                              placeholder="Категория номенклатуры"
                              options={[
                                {
                                  value: "service",
                                  label: "Услуга",
                                },
                                {
                                  value: "product",
                                  label: "Товар",
                                },
                              ]}
                            />
                          </Form.Item>
                          <Form.Item style={{ width: 200 }} name="contragent">
                            <DebounceSelect
                              showSearch
                              labelInValue
                              allowClear="true"
                              placeholder="Имя клиента"
                              fetchOptions={memoizedFetchContragents}
                              onChange={(data) =>
                                formFilter.setFieldValue("contragent", data.key)
                              }
                            />
                          </Form.Item>
                          <Form.Item name="date_range">
                            <RangePicker
                              onCalendarChange={handleDateRangeChange}
                              format="DD.MM.YYYY"
                            />
                          </Form.Item>
                          <Form.Item name="status_paid" style={{ width: 150 }}>
                            <Select
                              placeholder="Статус оплаты"
                              options={[
                                { value: "paid", label: "Оплачено" },
                                { value: "not_paid", label: "Не оплачено" },
                              ]}
                            />
                          </Form.Item>
                          <Form.Item
                            name={"status_rent"}
                            style={{ width: 150 }}
                          >
                            <Select
                              placeholder="Статус брони"
                              options={[
                                { value: "new", label: "Новый" },
                                { value: "confirmed", label: "Подтвержден" },
                                { value: "paid", label: "Оплачен" },
                                { value: "taken", label: "Забран" },
                                { value: "delivered", label: "Доставлен" },
                                { value: "unload", label: "Выгружен" },
                                { value: "prolong", label: "Пролонгирован" },
                                { value: "end", label: "Завершен" },
                              ]}
                            />
                          </Form.Item>
                        </Space>
                        <Space>
                          <Form.Item name="status_dept" style={{ width: 150 }}>
                            <Select
                              placeholder="Статус задолженности"
                              options={[
                                { value: "dept", label: "Должен" },
                                { value: "not_dept", label: "Не должен" },
                              ]}
                            />
                          </Form.Item>
                          <Form.Item name="date_create">
                            <DatePicker placeholder="Дата создания" />
                          </Form.Item>
                          <Form.Item name="date_payment">
                            <DatePicker placeholder="Дата оплаты" />
                          </Form.Item>
                          <Form.Item
                            name="tags_nomenclature"
                            style={{ width: 200 }}
                          >
                            <DebounceSelect
                              showSearch
                              labelInValue
                              allowClear="true"
                              placeholder="Теги номенклатуры"
                              fetchOptions={memoizedFetchNomenclature}
                              onChange={(data) =>
                                formFilter.setFieldValue(
                                  "tags_nomenclature",
                                  data.key
                                )
                              }
                            />
                          </Form.Item>
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={() => formFilter.resetFields()}
                            danger
                          >
                            Сбросить
                          </Button>
                          <Form.Item>
                            <Button
                              icon={<SearchOutlined />}
                              onClick={() =>
                                console.log(formFilter.getFieldsValue())
                              }
                            >
                              Поиск
                            </Button>
                          </Form.Item>
                        </Space>
                      </Form>
                    )}
                  </Panel>
                </Collapse>
                <Timeline
                  ref={timelineRef}
                  options={tlOptions}
                  // doubleClickHandler={handleDoubleClick}
                />
                {selectedItem && (
                  <Modal
                    title={"Детали заявки"}
                    open={isModalVisible}
                    onCancel={() => {
                      setIsModalVisible(false);
                      detailForm.resetFields();
                    }}
                    footer={[
                      <Button type="primary" onClick={handleChange}>
                        Изменить и сохранить
                      </Button>,
                      <Button
                        danger
                        onClick={() => {
                          message.warning("В разработке", 1);
                        }}
                      >
                        Удалить
                      </Button>,
                      <Button
                        onClick={() => {
                          setIsModalVisible(false);
                          detailForm.resetFields();
                        }}
                      >
                        Закрыть
                      </Button>,
                    ]}
                  >
                    <Tabs
                      defaultActiveKey="1"
                      items={[
                        {
                          key: "1",
                          label: "Заявка",
                          children: (
                            <RentForm
                              form={detailForm}
                              item={selectedItem}
                              token={token}
                            />
                          ),
                        },
                        {
                          key: "2",
                          label: "События",
                          children: (
                            <Space>
                              Здесь будут отображаться события Вашей заявки
                            </Space>
                            // <AntdTimeline items={[{ children: "test" }]} />
                          ),
                        },
                      ]}
                    />
                  </Modal>
                )}
                <Modal
                  title="Добавить новый элемент"
                  open={isAddModalVisible}
                  onCancel={() => {
                    setIsAddModalVisible(false);
                    createForm.resetFields();
                  }}
                  footer={[
                    <Button type="primary" onClick={handleAddNewItem}>
                      Создать бронь
                    </Button>,
                    <Button type="primary" onClick={handleAddNewItem}>
                      Создать бронь и заказ
                    </Button>,
                    <Button
                      danger
                      onClick={() => {
                        setIsAddModalVisible(false);
                        createForm.resetFields();
                      }}
                    >
                      Отмена
                    </Button>,
                  ]}
                >
                  <RentForm form={createForm} item={newItem} token={token} />
                  {/* <Form>
            <Form.Item label="Начало">
              <Input
                value={newItem.start}
                onChange={(e) =>
                  setNewItem({ ...newItem, start: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Конец">
              <Input
                value={newItem.end}
                onChange={(e) => setNewItem({ ...newItem, end: e.target.value })}
              />
            </Form.Item>
            <Form.Item label="Группа">
              <Input
                value={newItem.group}
                onChange={(e) =>
                  setNewItem({ ...newItem, group: e.target.value })
                }
              />
            </Form.Item>
          </Form> */}
                </Modal>
              </div>
            ),
          },
          {
            key: "2",
            label: "Настройки",
            children: (
              <Form form={settingsForm}>
                <Form.Item
                  label="Вызываемый бот amoCRM за 7 дней до окончания непролонгированной аренды"
                  name={"call_bot_7_days"}
                >
                  <Select style={{ width: 300 }} />
                </Form.Item>
                <Form.Item
                  label="Вызываемый бот amoCRM за 5 дней до окончания непролонгированной аренды"
                  name={"call_bot_5_days"}
                >
                  <Select style={{ width: 300 }} />
                </Form.Item>
                <Form.Item
                  label="Вызываемый бот amoCRM за 3 дней до окончания непролонгированной аренды"
                  name={"call_bot_3_days"}
                >
                  <Select style={{ width: 300 }} />
                </Form.Item>
              </Form>
            ),
          },
        ]}
      />
    </div>
  );
};

export default RentalChart;
