import { Alert, Button, Drawer, Form, Input } from "antd";
import React, { useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { apiRequests } from "../../../shared/api/api";
import NumericAutoComplete from "../../../shared/api/NumericAutoComplete";
// import { getDate } from "../../../shared/lib/utils/getDate";

import customParseFormat from "dayjs/plugin/customParseFormat";
// import debounce from "../../../shared/lib/utils/debounce";
import { MaskedInput } from "antd-mask-input";
import dayjs from "dayjs";
dayjs.extend(customParseFormat);

const AddContragentDrawer = ({ token, value, insertContragent }) => {
  const [isOpen, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [date, setDate] = useState("");
  const [isError, setError] = useState(false);

  const createContragent = async () => {
    const values = form.getFieldsValue();
    if (!(values.name_contragent && values.number_contragent)) {
      return setError(true);
    } else {
      const body = {
        name: values.name_contragent,
        phone: values.number_contragent.replace("+", ""),
        inn: !values.inn_contragent ? "" : values.inn_contragent,
        description: !values.pin_contragent ? "" : values.pin_contragent,
        contragent_type: "Покупатель",
      };

      if (date.length >= 1) {
        body["birth_date"] = date;
      }
      console.log(body);

      await apiRequests.contragents
        .post(body)
        .then((res) => {
          insertContragent(res.data);
          setOpen(false);
          form.resetFields();
        })
        .catch((e) => console.log(e));
    }
  };

  const createCardLoyality = async () => {
    const values = form.getFieldsValue();
    if (!(values.name_contragent && values.number_contragent)) {
      return setError(true);
    } else {
      const contragent_fields = {
        name: values.name_contragent,
        phone: values.number_contragent.replace("+", ""),
        inn: !values.inn_contragent ? "" : values.inn_contragent,
        description: !values.pin_contragent ? "" : values.pin_contragent,
        contragent_type: "Покупатель",
      };

      if (date.length >= 1) {
        contragent_fields["birth_date"] = date;
      }

      await apiRequests.contragents
        .post(contragent_fields)
        .then((contragent) => {
          apiRequests.loyalitySettings
            .getItems({ token: token })
            .then((res) => {
              return res.data.result[0];
            })
            .then((organization) => {
              const body = {
                card_number: contragent.data.phone,
                tags: "",
                phone_number: contragent.data.phone,
                contragent_id: contragent.data.id,
                contragent_name: contragent.data.name,
                organization_id: organization.organization,
                lifetime: organization.lifetime,
                status_card: true,
                is_deleted: false,
              };
              apiRequests.loyalityCards.post([body]).then((loyality) => {
                insertContragent(contragent.data);
                setOpen(false);
                form.resetFields();
              });
            });
        })
        .catch((e) => console.log(e));
    }
  };

  const onSelectCaPhone = (val) => {
    apiRequests.contragents.getItem(val).then((res) => {
      insertContragent(res.data);
      setOpen(false);
      form.resetFields();
    });
  };

  //   const handleDate = debounce((e) => {
  //     console.log(e);
  //     const parseDate = getDate(e);
  //     const fieldDate = dayjs(parseDate, "YYYY-MM-DD").format("DD-MM-YYYY");
  //     if (fieldDate === "Invalid Date") {
  //       form.setFieldValue("birthday_contragent", parseDate);
  //       setDate(dayjs(parseDate, "DD-MM-YYYY").format("YYYY-MM-DD"));
  //     } else {
  //       setDate(parseDate);
  //       form.setFieldValue("birthday_contragent", fieldDate);
  //     }
  //   }, 1700);

  return (
    <>
      <Button
        style={{
          width: "100%",
          marginTop: "10px",
        }}
        onClick={() => setOpen(true)}
        type={"primary"}>
        Создать контрагента
      </Button>

      <Drawer title={"Создание контрагента"} open={isOpen} onClose={() => setOpen(false)}>
        <Form form={form} layout={"vertical"}>
          {isError && (
            <Alert style={{ marginBottom: "15px" }} closable type="error" message="Заполните обязательные поля" />
          )}

          <Form.Item
            rules={[
              {
                required: true,
                message: "Поле не может быть пустым",
              },
            ]}
            name={"name_contragent"}
            label={"Имя контрагента"}
            messageVariables={"Поле не может быть пустым"}
            initialValue={isNaN(Number(value)) ? value : null}>
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Поле не может быть пустым",
              },
            ]}
            name={"number_contragent"}
            label={"Номер контрагента"}
            initialValue={!isNaN(Number(value)) ? value : null}
            messageVariables={"Поле не может быть пустым"}>
            <NumericAutoComplete
              api={`https://${process.env.REACT_APP_APP_URL}/api/v1/`}
              token={token}
              by={"phone"}
              onSelect={onSelectCaPhone}
            />
          </Form.Item>
          <Form.Item name={"inn_contragent"} label={"ИНН контрагента"} messageVariables={"Поле не может быть пустым"}>
            <NumericAutoComplete
              api={`https://${process.env.REACT_APP_APP_URL}/api/v1/`}
              token={token}
              by={"inn"}
              onSelect={onSelectCaPhone}
            />
          </Form.Item>
          <Form.Item name={"pin_contragent"} label={"Примечание"} messageVariables={"Поле не может быть пустым"}>
            <TextArea resize={"disabled"} rows={4} />
          </Form.Item>
          {/*<Form.Item*/}
          {/*    name={'birthday_contragent'}*/}
          {/*    label={'Дата рождения'}*/}
          {/*>*/}
          {/*    <DatePicker*/}
          {/*        onChange={(obj, dateString) => setDate(dateString)}*/}
          {/*    />*/}
          {/*</Form.Item>*/}

          <Form.Item name={"birthday_contragent"} label={"Дата рождения"}>
            <MaskedInput
              style={{ width: "110px" }}
              mask={"00-00-0000"}
              onChange={({ maskedValue, unmaskedValue }) => {
                if (unmaskedValue.length === 8) {
                  setDate(dayjs(maskedValue, "DD-MM-YYYY").format("YYYY-MM-DD").toString());
                }
              }}
            />
            {/* <InputNumber style={{ minWidth: "110px" }} onInput={handleDate} /> */}
          </Form.Item>

          <Form.Item style={{ width: "100%", marginBottom: 10 }}>
            <Button onClick={createContragent} style={{ width: "100%" }} type={"primary"}>
              Создать контрагента
            </Button>
          </Form.Item>
          <Form.Item style={{ width: "100%" }}>
            <Button onClick={createCardLoyality} style={{ width: "100%" }} type={"primary"}>
              Создать контрагента и карту
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default AddContragentDrawer;
