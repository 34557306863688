import { message } from "antd";
const downloadFile = (fileData, fileName, type) => {
  const blob = new Blob([fileData], { type: type });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  const pdfWindow = window.open();
  pdfWindow.location.href = link.href;
  document.body.removeChild(link);
  message.success({ content: "Файл успешно сгенерирован!", key: "loading" });
};

export default downloadFile;
