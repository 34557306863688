export default function searchValueById(initialData, filterData, key) {
  if (!filterData || filterData.length === 0) return initialData;
  let copyInitialData;
  if (initialData) {
    copyInitialData = JSON.parse(JSON.stringify(initialData));
  }
  if (copyInitialData && filterData && Array.isArray(initialData)) {
    const newData = copyInitialData.map((item) => {
      item[key] = {
        ...filterData.filter((value) => value.id === item[key]),
      }[0];
      return item;
    });
    return newData;
  }
  if (copyInitialData) {
    copyInitialData[key] = {
      ...filterData.filter((value) => value.id === copyInitialData[key]),
    };
  }

  return copyInitialData;
}
