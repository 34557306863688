import { useQuery } from "react-query";
import { useFetchAllData } from "src/shared/lib/hooks/context";
import { apiRequests } from "src/shared/api/api";

export const useFetchGetUsers = (options) => {
  const { token, name, } = options;
  const query = useQuery(
    ["users", token, name],
    async () => {
      const response = await apiRequests.users.getItems({ token, name });
      return response.data.result;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return query;
};

export const useFetchAllUsers = ({ token }) =>
  useFetchAllData({ token, key: "users", path: "users/" });

