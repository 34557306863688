import { useQuery, useMutation } from "react-query";
import { apiRequests } from "src/shared/api/api";
import { useFetchAllData } from "src/shared/lib/hooks/context";

export const useFetchGetPrices = (options) => {
  const { token, name, limit=10000 } = options;
  const query = useQuery(
    ["prices", token, name],
    async () => {
      const response = await apiRequests.prices.getItems({ token, name, limit });
      return response.data.result;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return query;
};

export const useFetchCreatePrice = (token, onSuccess) => {
  const { mutate, isLoading, isError, error } = useMutation(
    async (options) =>
      await apiRequests.prices.post(options)
  );
  return [mutate, { isLoading, isError, error }];
};

export const useFetchEditPrice = (token) => {
  const { mutate, isLoading, isError } = useMutation(
    async (options) =>
      await apiRequests.prices.patch(options)
  );
  return [mutate, { isLoading, isError }];
};

export const useFetchDeletePrices = (token) => {
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (ids) =>
      await apiRequests.prices.delete({
        params: { token, ids: ids.join(",") },
      })
  );
  return [mutate, { isLoading, isError, isSuccess }];
};

export const useFetchAllPrices = ({ token }) =>
  useFetchAllData({ token, key: "prices", path: "prices/" });