import { Button, Card, Modal, Space, Spin, message } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { downloadFile } from "src/shared";

const convertAmountToWords = (amount, currency) => {
  const words = [
    [
      "",
      "один",
      "два",
      "три",
      "четыре",
      "пять",
      "шесть",
      "семь",
      "восемь",
      "девять",
      "десять",
      "одиннадцать",
      "двенадцать",
      "тринадцать",
      "четырнадцать",
      "пятнадцать",
      "шестнадцать",
      "семнадцать",
      "восемнадцать",
      "девятнадцать",
    ],
    [
      "",
      "",
      "двадцать",
      "тридцать",
      "сорок",
      "пятьдесят",
      "шестьдесят",
      "семьдесят",
      "восемьдесят",
      "девяносто",
    ],
    [
      "",
      "сто",
      "двести",
      "триста",
      "четыреста",
      "пятьсот",
      "шестьсот",
      "семьсот",
      "восемьсот",
      "девятьсот",
    ],
  ];

  const rusRubles = ["рубль", "рубля", "рублей"];
  const belRubles = [
    "белорусский рубль",
    "белорусских рубля",
    "белорусских рублей",
  ];

  const toFloat = (number) => parseFloat(number);

  const plural = (count, options) => {
    if (options.length !== 3) {
      return false;
    }

    count = Math.abs(count) % 100;
    const rest = count % 10;

    if (count > 10 && count < 20) {
      return options[2];
    }

    if (rest > 1 && rest < 5) {
      return options[1];
    }

    if (rest === 1) {
      return options[0];
    }

    return options[2];
  };

  const parseNumber = (number, count, currCode) => {
    let first;
    let second;
    let numeral = "";

    if (number.length === 3) {
      first = number.substr(0, 1);
      number = number.substr(1, 3);
      numeral = "" + words[2][first] + " ";
    }

    if (number < 20) {
      numeral = numeral + words[0][toFloat(number)] + " ";
    } else {
      first = number.substr(0, 1);
      second = number.substr(1, 2);
      numeral = numeral + words[1][first] + " " + words[0][second] + " ";
    }

    if (count === 0) {
      switch (currCode) {
        case "BYN": {
          numeral = numeral + plural(number, belRubles);
          break;
        }
        case "RU":
        default: {
          numeral = numeral + plural(number, rusRubles);
        }
      }
    } else if (count === 1) {
      if (numeral !== "  ") {
        numeral = numeral + plural(number, ["тысяча ", "тысячи ", "тысяч "]);
        numeral = numeral.replace("один ", "одна ").replace("два ", "две ");
      }
    } else if (count === 2) {
      if (numeral !== "  ") {
        numeral =
          numeral + plural(number, ["миллион ", "миллиона ", "миллионов "]);
      }
    } else if (count === 3) {
      numeral =
        numeral + plural(number, ["миллиард ", "миллиарда ", "миллиардов "]);
    }

    return numeral;
  };

  const parseDecimals = (number) => {
    const text = plural(number, ["копейка", "копейки", "копеек"]);

    if (number === 0) {
      number = "00";
    } else if (number < 10) {
      number = "0" + number;
    }

    return " " + number + " " + text;
  };

  const rubles = (number, currCode) => {
    if (!number) {
      return false;
    }

    const type = typeof number;
    if (type !== "number" && type !== "string") {
      return false;
    }

    if (type === "string") {
      number = toFloat(number.replace(",", "."));

      if (isNaN(number)) {
        return false;
      }
    }

    if (number <= 0) {
      return false;
    }

    let splt;
    let decimals;

    number = number.toFixed(2);
    if (number.indexOf(".") !== -1) {
      splt = number.split(".");
      number = splt[0];
      decimals = splt[1];
    }

    let numeral = "";
    let length = number.length - 1;
    let parts = "";
    let count = 0;
    let digit;

    while (length >= 0) {
      digit = number.substr(length, 1);
      parts = digit + parts;

      if ((parts.length === 3 || length === 0) && !isNaN(toFloat(parts))) {
        numeral = parseNumber(parts, count, currCode) + numeral;
        parts = "";
        count++;
      }

      length--;
    }

    numeral = numeral.replace(/\s+/g, " ");

    if (decimals) {
      numeral = numeral + parseDecimals(toFloat(decimals));
    }

    return numeral;
  };

  return rubles(amount, currency);
};

// Функция для преобразования timestamp в формат "ДД-ММ-ГГГГ"
function timestampToDateFormat(timestampInSeconds) {
  var timestampInMillis = timestampInSeconds * 1000;

  var date = new Date(timestampInMillis);

  var day = date.getDate();
  var month = date.getMonth() + 1;
  var year = date.getFullYear();

  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  return day + "." + month + "." + year;
}

const PrintedFormDocsSales = ({ token, record }) => {
  const [isOpen, setOpen] = useState(false);
  const [docTempalates, setDocTemplates] = useState([]);
  const [products, setProducts] = useState([]);
  const [contragent, setContragent] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [wordAmount, setWordAmount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [bonusSum, setBonusSum] = useState(0);

  const openModal = () => {
    setOpen(true);
  };

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        if (isOpen) {
          const docTemplatesRes = await axios.get(
            `https://${process.env.REACT_APP_APP_URL}/api/v1/doctemplates/?token=${token}&limit=100&offset=0&page=docs_sales&area=listtableorder`
          );
          const docTemplatesData = docTemplatesRes.data.result;
          setDocTemplates(docTemplatesData);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchData = async () => {
      try {
        if (isOpen) {
          const productsRes = await axios.get(
            `https://${process.env.REACT_APP_APP_URL}/api/v1/docs_sales/${record.id}/?token=${token}`
          );
          const productsData = productsRes.data;
          setBonusSum(productsData.paid_loyality);
          setProducts(productsData);
          let forPaid = (productsData.sum - productsData.paid_loyality).toFixed(
            2
          );
          setWordAmount(convertAmountToWords(forPaid, "RU"));

          const organizationRes = await axios.get(
            `https://${process.env.REACT_APP_APP_URL}/api/v1/organizations/${productsData.organization}/?token=${token}`
          );
          const organizationData = organizationRes.data;
          setOrganization(organizationData);
          setIsLoading(false);

          const contragentRes = await axios.get(
            `https://${process.env.REACT_APP_APP_URL}/api/v1/contragents/${productsData.contragent}/?token=${token}`
          );
          const contragentData = contragentRes.data;
          setContragent(contragentData);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchDataAndTemplates = async () => {
      setIsLoading(true);
      await Promise.all([fetchTemplates(), fetchData()]);
      setIsLoading(false);
    };
    fetchDataAndTemplates();
  }, [isOpen, record.id, token, docTempalates.length]);

  const fetchDocGenerated = (item) => {
    message.loading({
      content: "Файл генерируется",
      key: "loading",
      duration: 0,
    });
    const requestData = {
      title: "Оплата",
      logo: "https://uploads.turbologo.com/uploads/design/preview_image/807421/preview_image20211222-743-19qazwo.png",
      purpose: products && products.comment ? products.comment : " ",
      seller: {
        kpp: organization && organization.kpp ? organization.kpp : " ",
        inn: organization && organization.inn ? organization.inn : " ",
        org_type:
          organization && organization.org_type ? organization.org_type : " ",
        name:
          organization && organization.full_name ? organization.full_name : " ",
      },
      buyer: {
        name: contragent && contragent.name ? contragent.name : " ",
        inn: contragent && contragent.inn ? contragent.inn : " ",
        kpp: contragent && contragent.kpp ? contragent.kpp : " ",
        description:
          contragent && contragent.description ? contragent.description : " ",
      },
      number: products.number,
      created_at: timestampToDateFormat(products.created_at),
      products: {
        list: products.goods.map((item) => ({
          name: item.nomenclature_name,
          count: item.quantity,
          unit: item.unit_name,
          price: item.price,
          discount: item.discount,
        })),
      },
      total_sum: products.sum,
      bonus_sum: bonusSum,
      for_paid: parseFloat((products.sum - bonusSum).toFixed(2)),
      total_sum_words: wordAmount
        ? wordAmount.charAt(0).toUpperCase() + wordAmount.slice(1)
        : "Ноль рублей 00 копеек",
    };

    axios
      .post(
        `https://${process.env.REACT_APP_APP_URL}/api/v1/docgenerated/?token=${token}&template_id=${item.id}&entity=${item.name}&type_doc=pdf&entity_id=${record.id}&tags=${item.tags}`,
        requestData
      )
      .then((response) => {
        const doc_link = response.data.doc_link.slice(13);
        navigator.clipboard.writeText(
          `https://${process.env.REACT_APP_APP_URL}/api/v1/docgenerated/file/${doc_link}/?type_doc=pdf`
        );
        axios
          .get(
            `https://${process.env.REACT_APP_APP_URL}/api/v1/docgenerated/file/${doc_link}/?type_doc=pdf`,
            { responseType: "blob" }
          )
          .then(async (response) => {
            const blobData = await response.data;
            downloadFile(blobData, doc_link, response.headers["content-type"]);
          })
          .catch((error) => {
            message.error({
              content: "Ошибка при открытии файла",
              key: "loading",
            });
          });
      })
      .catch((error) => {
        console.error("Ошибка при выполнении запроса:", error);
        message.error({
          content: "Не удалось сгенерировать файл",
          key: "loading",
        });
      });
  };

  return (
    <>
      <Button icon={<PrinterOutlined />} onClick={openModal} />
      <Modal
        title={"Формы для печати"}
        open={isOpen}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        {isLoading ? (
          <Space
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: 20,
            }}
          >
            <Spin />
          </Space>
        ) : docTempalates.length !== 0 ? (
          docTempalates.map((item) => (
            <Card
              key={item.id}
              styles={{
                body: {
                  padding: 16,
                  width: "100%",
                },
              }}
              style={{ marginBottom: 5 }}
              hoverable
              onClick={() => fetchDocGenerated(item)}
            >
              {item.description ? item.description : item.name}
            </Card>
          ))
        ) : (
          <div style={{ textAlign: "center", padding: 20 }}>
            Запросить настройку шаблонов вы можете в чате партнеров tablecrm.com
            в телеграм по{" "}
            <a
              href="https://t.me/productpartner"
              style={{ textDecoration: "none" }}
            >
              ссылке
            </a>{" "}
            или у вашего интегратора.
          </div>
        )}
      </Modal>
    </>
  );
};

export default PrintedFormDocsSales;
