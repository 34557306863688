import { Drawer, Modal, Form, Button, Space, Typography, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NomenclatureAutoComplete } from "src/shared";

const { Text } = Typography;

const findNomenclature = async (api, token, id) => {
  return fetch(`${api}nomenclature/${id}/?token=${token}`)
    .then((response) => response.json())
    .then((body) => {
      return body;
    });
};

function AddBarCode({ visible, token, barcode, close, api }) {
  const [open, isOpen] = useState();
  const [product, setProduct] = useState();
  const { confirm } = Modal;
  const [messageApi, contextHolder] = message.useMessage();

  const showConfirm = () => {
    confirm({
      title: "Внимание",
      content: "Штрихкод не найден. Хотите добавить данный штрихкод к товару?",
      onOk() {
        isOpen(true);
      },
      onCancel() {
        close();
        setProduct(null);
      },
    });
  };

  const onSelect = async (data) => {
    await findNomenclature(api, token, data).then((res) => {
      setProduct(res);
    });
  };

  const addBarcode = async () => {
    await axios
      .post(`${api}nomenclature/${product?.id}/barcode?token=${token}`, {
        barcode,
      })
      .then(() => {
        messageApi.open({
          type: "success",
          content: "Штрих-код успешно добавлен",
        });
        isOpen(false);
        setTimeout(() => {
          close();
          setProduct(null);
        }, 500);
      })
      .catch(() => {
        messageApi.open({
          type: "error",
          content: "При добавлении штрих-кода произошла ошибка",
        });
        isOpen(false);
        setTimeout(() => {
          close();
          setProduct(null);
        }, 500);
      });
  };

  useEffect(() => {
    console.log(visible);
    if (visible) {
      showConfirm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <>
      {contextHolder}
      <Drawer
        open={open}
        destroyOnClose
        maskClosable={false}
        closable={false}
        title="Добавить штрихкод к товару"
        width={"35%"}
        extra={
          <Space>
            <Button
              onClick={() => {
                isOpen(false);
                setTimeout(() => {
                  close();
                  setProduct(null);
                }, 500);
              }}>
              Отмена
            </Button>
            <Button onClick={() => addBarcode()} type="primary">
              Добавить
            </Button>
          </Space>
        }>
        <Form>
          <Form.Item name={"barcode"} label="штрих-код">
            {barcode}
          </Form.Item>
          <Form.Item name={"product"} label="выберите товар">
            <NomenclatureAutoComplete
              style={{ width: 200 }}
              placeholder={"Выберите товар"}
              api={api}
              token={token}
              onSelect={onSelect}
            />
          </Form.Item>
          <Form.Item>
            <Text style={{ color: "green" }}>{product?.name}</Text>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default AddBarCode;
