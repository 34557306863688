import React, { useContext, useState } from "react";
import { API } from "../../Table";
import { Form } from "antd";
import { ModalForm } from "src/enitities/Modal";
import { ContractsForm, serializeTimestamp } from "src/enitities/Form";
import { ContractsContext } from "src/shared";

export default function AddContracts({ isOpen, setOpen }) {
  const [changeData, setChangeData] = useState(false);
  const { token, pathname } = useContext(ContractsContext);
  const [form] = Form.useForm();
  const handleSubmit = async (data) => {
    const newData = [
      serializeTimestamp(data[0], ["dated", "used_from", "used_to"]),
    ];
    const querySave = API.crud.create(token, pathname);
    try {
      await querySave(newData);
      return true;
    } catch (err) {
      return Promise.reject(err.message.detail);
    }
  };

  return (
    <ModalForm
      title={"Добавить контракт"}
      isOpen={isOpen}
      setOpen={setOpen}
      formContext={form}
      handleSubmit={handleSubmit}
      setChangeData={setChangeData}
      changeData={changeData}
    >
      <ContractsForm
        formContext={form}
        switchButton={true}
        setChangeData={setChangeData}
      />
    </ModalForm>
  );
}
