import {ModalForm} from "../../../enitities/Modal";
import {Form, Input} from "antd";
import useForm from "antd/lib/form/hooks/useForm";
import {useContext, useState} from "react";
import {PriceTypesContent} from "../../../shared/lib/hooks/context/getPriceTypesContext";
import {axiosInstance} from "../../../shared/api/axiosInstance";

const AddPriceTypeModal = ({isOpen, setOpen}) => {
    const [form] = useForm()
    const [changeData, setChangeData] = useState(false);
    const {token, setPriceType, priceTypeData} = useContext(PriceTypesContent)


    // FIX
    const handleSubmit = async () => {
        const fields = form.getFieldsValue()
        await axiosInstance.post(`/price_types/?token=${token}`, fields)
            .then((i) => {
                const newData = {...priceTypeData}
                newData.priceTypeDS.push({...i.data})

                setPriceType(newData)
            }).catch((e) => {
                return Promise.reject(e.message.detail);
            })
    }

    return (
        <>
            <ModalForm
                title={'Создать тип цены'}
                width={550}
                isOpen={isOpen}
                setOpen={setOpen}
                handleSubmit={handleSubmit}
                formContext={form}
                changeData={changeData}
                setChangeData={setChangeData}
            >
                <Form
                    labelCol={{span: 7}}
                    wrapperCol={{span: 15}}
                    form={form}
                    layout={'horizontal'}
                    style={{ maxWidth: "100%" }}
                >
                    <Form.Item
                        rules={[{ required: true, message: 'Поле обязательно к заполнению'}]}
                        label={'Название'}
                        name={'name'}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </ModalForm>
        </>
    )
}

export default AddPriceTypeModal