// страница "Аналитика"
import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import { Menu, Modal, Calendar, Table, Space, Input, Button } from "antd";
import {
  CalendarOutlined,
  CheckCircleOutlined,
  StopOutlined,
  InfoCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { AnalysticsFilter, SalesReportFilter } from "src/features/Table";
import locale from "antd/es/date-picker/locale/ru_RU";
import moment from "moment";
import { apiRequests } from "src/shared/api/api";

moment.locale("ru");

dayjs.extend(utc);

// const { Text } = Typography;

const nav_items = (token) => [
  { label: "Календарь платежей", key: "1", icon: <CalendarOutlined /> },
  { label: "Отчет по продажам", key: "2", icon: <CalendarOutlined /> },
  { label: "График аналитики", key: "3", icon: <CalendarOutlined /> },
];

const Analytics = ({ paymentsData, listPayment, query, filter, websocket }) => {
  const [arr, setArr] = useState([]);
  const [payboxData, setPayboxData] = useState({ data: [], isLoad: false });
  const [salesData, setSalesData] = useState({ data: [], isLoad: false });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [item, setItem] = useState({});
  const [menuItem, setMenuItem] = useState("1");

  useEffect(() => {
    setArr(listPayment);
  }, [listPayment]);

  websocket.onmessage = (message) => {
    const data = JSON.parse(message.data);

    if (data.target === "payments") {
      if (data.action === "create") {
        setArr(arr.concat(data.result));
      }
    }
    if (data.action === "edit") {
      const newData = [...arr];
      const index = newData.findIndex((item) => data.result.id === item.id);

      if (index !== -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...data.result });
        setArr(newData);
      }
    }

    if (data.action === "delete") {
      let arrDelete = arr.filter((item) => item.id !== data.result);
      setArr(arrDelete);
    }
  };

  const showModal = (item) => {
    setIsModalOpen(true);
    setItem(item);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setItem({});
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setItem({});
  };

  const dateCellRender = (value) => {
    return (
      <div>
        {arr?.map((item, idx) =>
          dayjs.unix(item.date).date() === value.date() &&
          dayjs.unix(item.date).month() === value.month() &&
          dayjs.unix(item.date).year() === value.year() ? (
            <div
              className="title__payment"
              onClick={() => showModal(item)}
              key={idx}
            >
              {item.status ? (
                <CheckCircleOutlined
                  style={{
                    color: item.type === "outgoing" ? "red" : "green",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <StopOutlined
                  style={{
                    color: item.type === "outgoing" ? "red" : "green",
                    marginRight: "10px",
                  }}
                />
              )}
              <span className={`${item.type}`}>{item.amount}</span>
            </div>
          ) : (
            <div />
          )
        )}
      </div>
    );
  };

  const monthCellRender = (value) => {
    const month = value.month();
    return month;
  };

  const onPanelChange = (value) => {
    const startOfMonth = dayjs(value).startOf("month").format("DD-MM-YYYY");
    const endOfMonth = dayjs(value).endOf("month").format("DD-MM-YYYY");
    const values = { datefrom: startOfMonth, dateto: endOfMonth };
    handleFeatch(1, values);
  };

  const cellRender = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    if (info.type === "month") return monthCellRender(current);
    return info.originNode;
  };

  const handleFeatch = (page, pars, sort = null) => {
    const limit = 35;
    const offset = page * 35 - 35 || 35;
    let filters = pars;
    let params = {
      params: {
        token: query.token,
        limit: limit,
        offset: offset,
        ...filters,
      },
    };

    apiRequests.payments.getItems(params).then((response) => {
      const newArr = [
        ...new Set(
          response.data.result.map((item) => ({
            type: item.type,
            amount: item.amount,
            date: item.date,
            name: item.name,
          }))
        ),
      ];
      setArr(newArr);
    });

    const serialize = (obj) => {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    };

    const newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?" +
      serialize({ ...query, ...filters });
    window.history.pushState({ path: newurl }, "", newurl);
  };
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Введите номенклатуру`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Поиск
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Сбросить
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Закрыть
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => {
      if (searchedColumn === dataIndex && searchText) {
        const regex = new RegExp(`(${searchText})`, "gi");
        const parts = text.toString().split(regex);
        return (
          <span>
            {parts.map((part, index) =>
              part.toLowerCase() === searchText.toLowerCase() ? (
                <span key={index} style={{ backgroundColor: "#ffc069" }}>
                  {part}
                </span>
              ) : (
                part
              )
            )}
          </span>
        );
      }
      return text;
    },
  });

  const columns_sales = [
    {
      title: "Название товара",
      key: "nomenclature_name",
      width: "50%",
      dataIndex: "nomenclature_name",
      ...getColumnSearchProps("nomenclature_name"),
    },
    {
      title: "Количество",
      key: "count",
      width: "25%",
      dataIndex: "count",
    },
    {
      title: "Сумма",
      key: "sum",
      width: "25%",
      dataIndex: "sum",
    },
  ];

  const columns_paybox = [
    {
      title: "Счет",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Приход",
      key: "incoming",
      dataIndex: "incoming",
    },
    {
      title: "Расход",
      key: "outgoing",
      dataIndex: "outgoing",
    },
    {
      title: "Остаток",
      key: "balance",
      dataIndex: "balance",
    },
  ];

  const handleFeatchSalesReport = async (values) => {
    setPayboxData({ isLoad: true });
    setSalesData({ isLoad: true });
    let payboxes = [];
    if (values?.payboxes) {
      payboxes = [
        ...values?.payboxes?.map((item) => {
          return item.key;
        }),
      ];
    }

    if (values?.dates[0].unix() === values?.dates[1].unix()) {
    }
    apiRequests.reports
      .getSalesBalances({
        token: query?.token,
        paybox: payboxes,
        datefrom: values?.dates[0].unix(),
        dateto: values?.dates[1].unix() + 86399,
        user: values?.users?.key || null,
      })
      .then((responce) => {
        setPayboxData((prev) => ({
          ...prev,
          data: responce.data,
          isLoad: false,
        }));
      })
      .catch((err) => {
        setPayboxData({ isLoad: false });
      });

    apiRequests.reports
      .getSalesReport({
        token: query?.token,
        paybox: payboxes,
        datefrom: values?.dates[0].unix(),
        dateto: values?.dates[1].unix() + 86399,
        user: values?.users?.key || null,
      })
      .then((responce) => {
        setSalesData((prev) => ({
          ...prev,
          data: responce.data,
          isLoad: false,
        }));
      })
      .catch((err) => {
        setSalesData({ isLoad: false });
      });
  };

  return (
    <div className="content">
      {menuItem === "1" && (
        <AnalysticsFilter query={query} onChange={handleFeatch} />
      )}
      {menuItem === "2" && (
        <SalesReportFilter query={query} onChange={handleFeatchSalesReport} />
      )}
      <Menu
        theme="light"
        defaultSelectedKeys={["1"]}
        mode="horizontal"
        onSelect={(value) => setMenuItem(value.key)}
        items={nav_items(query.token)}
        style={{ float: "left", marginBottom: "20px" }}
      />
      <Modal
        title="Информация о платеже"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
          <label>Название платежа:</label>
          <h3>{item.name}</h3>
        </div>
        <div>
          <label>Сумма платежа:</label>
          <h3>{item.amount}</h3>
        </div>
        <div>
          <label>Дата платежа:</label>
          <h3>{dayjs.unix(item.date).format("DD-MM-YYYY")}</h3>
        </div>
        <div>
          <label>Статус платежа:</label>
          <p>
            {item.type === "incoming" ? (
              <CheckCircleOutlined
                style={{ color: "green", fontSize: "60px" }}
              />
            ) : item.type === "outgoing" ? (
              <StopOutlined style={{ color: "red", fontSize: "60px" }} />
            ) : (
              <InfoCircleOutlined style={{ fontSize: "60px" }} />
            )}
          </p>
        </div>
      </Modal>
      {menuItem === "1" && (
        <Calendar
          cellRender={cellRender}
          onPanelChange={onPanelChange}
          locale={locale}
          mode={"month"}
        />
      )}
      {menuItem === "2" && (
        <>
          <Space direction={"vertical"} size={"middle"}>
            <Table
              dataSource={salesData.data}
              columns={columns_sales}
              loading={salesData.isLoad}
              title={() => (
                <span>
                  <b>Продажи</b>
                </span>
              )}
              pagination={false}
              size="middle"
              bordered
              scroll={{
                y: 500,
              }}
              summary={(pageData) => {
                let totalBorrow = 0;
                let totalRepayment = 0;
                pageData.forEach(({ count, sum }) => {
                  totalBorrow += count;
                  totalRepayment += sum;
                });
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>итого</Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>
                        {totalBorrow}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        {totalRepayment}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
            <Table
              dataSource={payboxData.data}
              columns={columns_paybox}
              loading={payboxData.isLoad}
              pagination={false}
              title={() => (
                <span>
                  <b>Счета и баланс</b>
                </span>
              )}
              size="middle"
              bordered
              scroll={{
                y: 300,
              }}
              summary={(pageData) => {
                let totalBorrow = 0;
                let totalRepayment = 0;
                let totalBalance = 0;
                pageData.forEach(({ incoming, outgoing, balance }) => {
                  totalBorrow += incoming;
                  totalRepayment += outgoing;
                  totalBalance += balance;
                });
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>итого</Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>
                        {totalBorrow}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        {totalRepayment}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        {totalBalance}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Space>
        </>
      )}
      {menuItem === "3" && (
        <div></div>
        // <AnalyticChart data={paymentsData} />
      )}
    </div>
  );
};

export default Analytics;
