import { axiosInstance } from "./axiosInstance";
import { message } from "antd";

export const API = {
  pictures: {
    removeImage: (token) => async (id) =>
      await axiosInstance
        .delete(`/pictures/${id}/?token=${token}`)
        .then(() => message.success("Изображение было удалено"))
        .catch((err) => Promise.reject(err)),
  },
  crud: {
    create: (token, url) => async (data) => {
      await axiosInstance
        .post(`${url}/?token=${token}`, data)
        .then((result) => {
          message.success("Вы успешно создали строку");
          return result;
        })
        .catch((err) => Promise.reject(err));
    },
    edit: (token, url) => async (data, id) =>
      await axiosInstance
        .patch(`${url}/${data.id || id}/?token=${token}`, data)
        .then((result) => {
          message.success("Строка была успешнo отредактирована");
          return result;
        })
        .catch((err) => Promise.reject(err)),
    editTwo: (token, url) => async (data) =>
      await axiosInstance
        .patch(`${url}/?token=${token}`, data)
        .then((result) => {
          message.success("Строка была успешнo отредактирована");
          return result;
        })
        .catch((err) => Promise.reject(err)),
    remove: (token, url) => async (id, data) =>
      await axiosInstance
        .delete(
          `${url}/${id}/?token=${token}`,
          !!data ? { data: data } : undefined
        )
        .then(() => message.success("Строка была успешно удалена"))
        .catch((err) => Promise.reject(err)),
    get: (token, url) => async (id, params) =>
      await axiosInstance
        .get(`${url}/${id || ""}/?token=${token}`, { params: params || "" })
        .then((item) => item.data)
        .catch((err) => Promise.reject(err)),
  },
  search: (token, url) => async (params) =>
    await axiosInstance
      .get(`${url}/?token=${token}`, { params: params })
      .then((item) => item.data)
      .catch((err) => Promise.reject(err)),
};

class APIRequests {
  token;

  constructor() {
    axiosInstance.interceptors.request.use((config) => {
      config.params = {
        ...(config.params || {}),
        token: this.token,
      };
      return config;
    });
  }

  setToken(token) {
    this.token = token;
  }

  articles = {
    getItem: (id) => {
      return axiosInstance.get(`/articles/${id}/`);
    },
    post: (payload) => {
      return axiosInstance.post(`/articles/`, payload);
    },
    put: (id, payload) => {
      return axiosInstance.put(`/articles/${id}/`, payload);
    },
  };
  payments = {
    getMeta: (params = {}) => {
      return axiosInstance.get(`/payments_meta/`, {
        params,
      });
    },
    getItems: (params = {}) => {
      return axiosInstance.get(`/payments/`, {
        params,
      });
    },
    post: (payload) => {
      return axiosInstance.post(`/payments/`, payload);
    },
    put: (id, payload) => {
      return axiosInstance.put(`/payments/${id}/`, payload);
    },
    delete: (id, payload) => {
      return axiosInstance.delete(`/payments/${id}/`, payload);
    },
  };

  projects = {
    getItems: (params = {}) => {
      return axiosInstance.get("/projects/", { params });
    },
    post: (payload) => {
      return axiosInstance.post(`/projects/`, payload);
    },
    put: (payload) => {
      return axiosInstance.put(`/projects/`, payload);
    },
  };

  payboxes = {
    getItems: (params = {}) => {
      return axiosInstance.get("/payboxes/", { params });
    },
    post: (payload) => {
      return axiosInstance.post("/payboxes/", payload);
    },
    put: (payload) => {
      return axiosInstance.put("/payboxes/", payload);
    },
  };

  cashboxes = {
    getMeta: (params = {}) => {
      return axiosInstance.get("/cashboxes_meta/", { params });
    },
  };

  categories = {
    getItems: (params = {}) => {
      return axiosInstance.get("/categories/", { params });
    },
    post: (payload) => {
      return axiosInstance.post("/categories/", payload);
    },
  };

  contragents = {
    getItems: (params = {}) => {
      return axiosInstance.get("/contragents/", { params });
    },
    getItem: (id) => {
      return axiosInstance.get(`/contragents/${id}/`);
    },
    post: (payload) => {
      return axiosInstance.post("/contragents/", payload);
    },
    put: (id, payload) => {
      return axiosInstance.put(`/contragents/${id}/`, payload);
    },
    delete: (id, payload) => {
      return axiosInstance.delete(`/contragents/${id}/`, payload);
    },
  };

  loyalityCards = {
    getItems: (params = {}) => {
      return axiosInstance.get("/loyality_cards/", { params });
    },
    post: (payload) => {
      return axiosInstance.post("/loyality_cards/", payload);
    },
    patch: (id, payload) => {
      return axiosInstance.patch(`/loyality_cards/${id}/`, payload);
    },
    delete: (id, payload) => {
      return axiosInstance.delete(`/loyality_cards/${id}/`, payload);
    },
    getById: (id) => {
      return axiosInstance.get(`/loyality_cards/${id}/`);
    },
  };
  loyalityTransactions = {
    getItems: (params = {}) => {
      return axiosInstance.get("/loyality_transactions/", { params });
    },
    post: (payload) => {
      return axiosInstance.post("/loyality_transactions/", payload);
    },
    patch: (id, payload) => {
      return axiosInstance.patch(`/loyality_transactions/${id}/`, payload);
    },
    delete: (id, payload) => {
      return axiosInstance.delete(`/loyality_transactions/${id}/`, payload);
    },
  };
  loyalitySettings = {
    getItems: (params = {}) => {
      return axiosInstance.get("/loyality_settings/", { params });
    },
  };
  contracts = {
    getItems: (params = {}) => {
      return axiosInstance.get("/contracts/", { params });
    },
  };
  organizations = {
    getItems: (params = {}) => {
      return axiosInstance.get("/organizations/", { params });
    },
  };
  warehouses = {
    getItems: (params = {}) => {
      return axiosInstance.get("/warehouses/", { params });
    },
    getBalances: (id, params = {}) => {
      return axiosInstance.get(`/warehouses/${id}/`, { params });
    },
  };
  nomenclature = {
    getItems: (params = {}) => {
      return axiosInstance.get(`/nomenclature/`, { params });
    },
    getItem: (id, params = {}) => {
      return axiosInstance.get(`/nomenclature/${id}/`, { params });
    },
    post: (payload) => {
      return axiosInstance.post(`/nomenclature/`, payload);
    },
    patch: (payload) => {
      return axiosInstance.patch(`/nomenclature/`, payload);
    },
    delete: (payload) => {
      return axiosInstance.delete(`/nomenclature/`, payload);
    },
  };
  prices = {
    getItems: (params = {}) => {
      return axiosInstance.get(`/prices/`, { params });
    },
    getItem: (id, params = {}) => {
      return axiosInstance.get(`/prices/${id}/`, { params });
    },
    getAltItem: (id, params = {}) => {
      return axiosInstance.get(`/alt_prices/${id}/`, { params });
    },
    getTypes: (params = {}) => {
      return axiosInstance.get(`/price_types/`, { params });
    },
    post: (payload) => {
      return axiosInstance.post(`/prices/`, payload);
    },
    patch: (payload) => {
      return axiosInstance.patch(`/prices/`, payload);
    },
    delete: (payload) => {
      return axiosInstance.delete(`/prices/`, payload);
    },
  };
  priceTypes = {
    getItems: (payload) => {
      return axiosInstance.get("/price_types/", payload);
    },
  };
  pictures = {
    getItems: (params = {}) => {
      return axiosInstance.get(`/pictures/`, { params });
    },
  };
  manufacturers = {
    getItems: (params = {}) => {
      return axiosInstance.get(`/manufacturers/`, { params });
    },
  };
  units = {
    getItems: (params = {}) => {
      return axiosInstance.get(`/units/`, { params });
    },
  };
  docs = {
    warehouse: (params = {}) => {
      return axiosInstance.get(`/docs_warehouse/`, { params });
    },
    deleteWarehouse: (payload) => {
      return axiosInstance.delete(`/docs_warehouse/${payload}`);
    },
    purchases: (params = {}) => {
      return axiosInstance.get(`/docs_purchases/`, { params });
    },
    postPurchases: (payload) => {
      return axiosInstance.post(`/docs_purchases/`, payload);
    },
    patchPurchases: (payload) => {
      return axiosInstance.patch(`/docs_purchases/`, payload);
    },
    patchPurchase: (id, payload) => {
      return axiosInstance.patch(`/docs_purchases/${id}/`, payload);
    },
    deletePurchase: (id, payload) => {
      return axiosInstance.delete(`/docs_purchases/${id}/`, payload);
    },
    purchasesItem: (id, params = {}) => {
      return axiosInstance.get(`/docs_purchases/${id}/`, { params });
    },
    sales: (params = {}) => {
      return axiosInstance.get(`/docs_sales/`, { params });
    },
    patchSales: (id, payload) => {
      return axiosInstance.patch(`/docs_sales/${id}/`, payload);
    },
    salesItem: (id, params = {}) => {
      return axiosInstance.get(`/docs_sales/${id}/`, { params });
    },
    postSales: (payload) => {
      return axiosInstance.post(`/docs_sales/`, payload);
    },
  };

  altDocs = {
    postWarehouse: (payload) => {
      return axiosInstance.post("/alt_docs_warehouse/", payload);
    },
    patchWarehouse: (payload) => {
      return axiosInstance.patch("/alt_docs_warehouse/", payload);
    },
  };

  users = {
    getItems: (params = {}) => {
      return axiosInstance.get("/cashbox_users/", { params });
    },
    put: (params = {}) => {
      return axiosInstance.put(`/cashbox_users/`, {}, { params: params });
    },
  };

  analytics = {
    getCards: (params = {}) => {
      return axiosInstance.get("/analytics_cards/", { params });
    },
  };
  integrationPair = {
    getItems: (params = {}) => {
      return axiosInstance.get("/integration_pair/", { params });
    },
  };

  integrationTochka = {
    getLink: (params = {}) => {
      return axiosInstance.get("/bank/get_oauth_link/", { params });
    },
    getIntegrationOn: (params = {}) => {
      return axiosInstance.get("/bank/integration_on", { params });
    },
    getIntegrationOff: (params = {}) => {
      return axiosInstance.get("/bank/integration_off", { params });
    },
    getIntegrationCheck: (params = {}) => {
      return axiosInstance.get("/bank/check", { params });
    },
    getIntegrationAccounts: (params = {}) => {
      return axiosInstance.get("/bank/accounts/", { params });
    },
    patchIntegrationAccount: (id, payload) => {
      return axiosInstance.patch(`/bank/accounts/update/${id}/`, payload);
    },
  };
  integrationModule = {
    getLink: (params = {}) => {
      return axiosInstance.get("/module_bank/get_oauth_link/", { params });
    },
    getBills: (params = {}) => {
      return axiosInstance.get("/module_bank/accounts/", { params });
    },
    getIntegrationOn: (params = {}) => {
      return axiosInstance.get("/module_bank/integration_on", { params });
    },
    getIntegrationOff: (params = {}) => {
      return axiosInstance.get("/module_bank/integration_off", { params });
    },
    getIntegrationCheck: (params = {}) => {
      return axiosInstance.get("/module_bank/check", { params });
    },
    getIntegrationAccounts: (params = {}) => {
      return axiosInstance.get("/bank/accounts/", { params });
    },
    patchIntegrationAccount: (id, payload) => {
      return axiosInstance.patch(`/bank/accounts/update/${id}/`, payload);
    },
  };
  integrationEvotor = {
    getIntegrationOn: (params = {}) => {
      return axiosInstance.get("/evotor/integration/on", { params });
    },
    getIntegrationOff: (params = {}) => {
      return axiosInstance.get("/evotor/integration/off", { params });
    },
    getIntegrationInstall: (params = {}) => {
      return axiosInstance.get("/evotor/integration/install", { params });
    },
    getIntegrationCheck: (params = {}) => {
      return axiosInstance.get("/evotor/integration/check", { params });
    },
  };

  reports = {
    getSalesBalances: (payload) => {
      return axiosInstance.post("/reports/balances/", payload);
    },
    getSalesReport: (payload) => {
      return axiosInstance.post("/reports/sales/", payload);
    },
  };

  booking = {
    getItems: (params = {}) => {
      return axiosInstance.get("/booking/list/", { params });
    },
    getItemById: (id, params = {}) => {
      return axiosInstance.get(`/booking/${id}/`, { params });
    },
    post: (payload) => {
      return axiosInstance.post(`/booking/create`, payload);
    },
    patch: (payload) => {
      return axiosInstance.patch(`/booking/edit/`, payload);
    },
  };
}

export const apiRequests = new APIRequests();
