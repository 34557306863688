import { useQuery } from "react-query";
import { useFetchAllData } from "src/shared/lib/hooks/context";
import { apiRequests } from "src/shared/api/api";

export const useFetchGetUnits = ({ limit, offset }) => {
  const query = useQuery(
    ["units"],
    async () => {
      const response = await apiRequests.units.getItems({ offset, limit });
      return response.data.result;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return query;
};

export const useFetchAllUnits = () =>
  useFetchAllData({ key: "units", path: "units/" });
